import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {VideoData} from "../constants/const";
import VideoItem from "../components/VideoItem";

function HowToPlay () {
    const [size, setSize] = useState<any>(null);
    const {t} = useTranslation();

    const handleVideo = (el: any) => {
        setSize(el)
    };

    return (
        <div className="how-to-play">
            <div className="video-block-header">
                {t('Как играть')}?
            </div>
            <div className="video-block">
                {VideoData.map((video, index) => {
                    return (
                        <VideoItem
                            size={size}
                            close={() => setSize(null)}
                            func={() => handleVideo(index)}
                            index={index}
                            key={index}
                            src={video.src}
                            image={video.image}
                            text={video.text}
                        />
                    )
                })}
            </div>

        </div>
    )
}

export default HowToPlay