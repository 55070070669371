import React from "react";
import {useTranslation} from "react-i18next";
import {Collapse} from "antd";
import {RuleItems} from "../constants/const";
import CollapseIcon from "../assets/icons/collapse-icon.svg"
import leftArrow from "../assets/icons/left-arrow.svg";
import {useNavigate} from "react-router";

function Rules () {
    const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <div className="rules">
            <div className="jackpot_title">
                <img src={leftArrow}
                     alt="icon"
                     width={32}
                     height={32}
                     onClick={() => {navigate('/more')}}
                />
                <span>{t('Правила')}</span>
            </div>
            {RuleItems.map((item:any, index) => {
                return (
                    <Collapse
                        items={[
                            {
                                key: index,
                                label:
                                    <div className="collapse-item">
                                        <div className="collapse-item-number">{index + 1}</div>
                                        <div className="collapse-item-text">{t(item.title)}</div>
                                    </div>,
                                children: <span>
                                    {item.content_1?<>{index + 1}.1. {t(item.content_1)}</>: ""}<br/>
                                    {item.content_2?<>{index + 1}.2. {t(item.content_2)}</>: ""}<br/>
                                    {item.content_3?<>{index + 1}.3. {t(item.content_3)}</>: ""}<br/>
                                    </span>
                            }]}
                        rootClassName="rule-collapse"
                        key={index}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={() => <img src={CollapseIcon} alt="icon"/>}
                    />
                )
            })
            }
        </div>
    )
}

export default Rules