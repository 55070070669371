import React from "react";
import {useTranslation} from "react-i18next";

function MainButton (props:any) {
    const {t} = useTranslation();

    return (
        <div className="main-button" onClick={props.func}>
            {props.icon? <img src={props.icon} alt=""/>: ""}
            <span>{t(props.text)}</span>
            {props.price ? <span>{props.price} USDT</span>: ""}
        </div>
    )
}

export default MainButton