import React from "react";
import Dot from "../assets/icons/dot.svg";
import {useTranslation} from "react-i18next";

function Completionist (props:any) {

    const {t} = useTranslation();

    return (
        <>
            <div className={"datetime-content"}>
                <div className={!props.isMini ? "datetime-number": "mini-datetime-number"}>{props.days}</div>
                <div className="datetime-text">{t("Дней")}</div>
            </div>
            <span><img src={Dot} alt="icon"/><img src={Dot} alt="icon"/></span>
            <div className="datetime-content">
                <div className={!props.isMini ? "datetime-number": "mini-datetime-number"}>{props.hours}</div>
                <div className="datetime-text">{t("Часов")}</div>
            </div>
            <span><img src={Dot} alt="icon"/><img src={Dot} alt="icon"/></span>
            <div className="datetime-content">
                <div className={!props.isMini ? "datetime-number": "mini-datetime-number"}>{props.minutes}</div>
                <div className="datetime-text">{t("Минуты")}</div>
            </div>
            <span><img src={Dot} alt="icon"/><img src={Dot} alt="icon"/></span>
            <div className="datetime-content">
                <div className={!props.isMini ? "datetime-number": "mini-datetime-number"}>{props.seconds}</div>
                <div className="datetime-text">{t("Секунды")}</div>
            </div>
        </>
    );
};

export default Completionist