import React from "react";
import leftArrow from "../assets/icons/left-arrow.svg";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

function PrivacyPolicy() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="global-component">
            <div className="jackpot_title">
                <img src={leftArrow}
                     alt="icon"
                     width={32}
                     height={32}
                     onClick={() => {
                         navigate('/more')
                     }}
                />
                <span>{t('Политика конфиденциальности')}</span>
            </div>
            <div className="global-component_item">
                <div className="global-component_item__title">
                    Title1
                </div>
                <div className="global-component_item__content">
                    Lorem ipsum dolor sit amet consectetur. Interdum sed hac facilisis ut tellus lobortis. Faucibus at netus cum nam non curabitur arcu id. Odio ac vitae viverra felis egestas gravida proin. A id in a suspendisse congue. Proin erat nunc ut fermentum semper blandit ultricies at.

                    Sollicitudin dolor orci a dictumst. Feugiat sit convallis tristique convallis. Ultrices nulla nunc diam magna. Vestibulum a feugiat ut tristique at risus. Odio urna accumsan tortor amet lacus interdum nunc fames. Ac in integer posuere amet posuere.
                </div>
            </div>
            <div className="global-component_item">
                <div className="global-component_item__title">
                    Title2
                </div>
                <div className="global-component_item__content">
                    Lorem ipsum dolor sit amet consectetur. Interdum sed hac facilisis ut tellus lobortis. Faucibus at netus cum nam non curabitur arcu id. Odio ac vitae viverra felis egestas gravida proin. A id in a suspendisse congue. Proin erat nunc ut fermentum semper blandit ultricies at.

                    Sollicitudin dolor orci a dictumst. Feugiat sit convallis tristique convallis. Ultrices nulla nunc diam magna.
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy