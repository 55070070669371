import React from "react";
import leftArrow from "../assets/icons/left-arrow.svg";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {userTermsInfo} from "../constants/const";

function UserTerms() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="global-component">
            <div className="jackpot_title">
                <img src={leftArrow}
                     alt="icon"
                     width={32}
                     height={32}
                     onClick={() => {
                         navigate('/more')
                     }}
                />
                <span>{t('Условия пользователя')}</span>
            </div>
            {userTermsInfo.map((item,index) => {
                return (
                    <div className="global-component_item" key={index}>
                        <div className="global-component_item__title">
                            {t(item.title)}
                        </div>
                        <div className="global-component_item__content">
                            {t(item.content)}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default UserTerms