export const translationEN = {
    "Джекпот": "Jackpot",
    "Доступные билеты": "Available tickets",
    "Приобретайте билеты нашей лотереи по доступной цене в 25 USDT за билет. Чем больше билетов у вас есть, тем больше шансов на выигрыш вы имеете!": "Buy our lottery tickets at an affordable price of 25 USDT per ticket. The more tickets you have, the more chances you have to win!",
    "Купить билет": "Buy a ticket",
    "У вас есть шанс стать обладателем огромного приза, который изменит вашу жизнь навсегда. Время идет!": "You have a chance to win an award that will change your life forever. Time is ticking!",
    "Дней": "Days",
    "Часов": "Hours",
    "Минуты": "Minutes",
    "Секунды": "Seconds",
    "Мега Джекпот": "Mega Jackpot",
    "Игры": "Games",
    "Друзья": "Friends",
    "Как играть": "How to play",
    "Профиль": "Profile",
    "Ещё": "More",
    "Ваша транзакция успешно выполнена, и теперь у вас есть билет на участие в конкурсе. Желаем вам удачи и надеемся, что ваше участие будет успешным!": "Your transaction was successful and you now have a ticket to enter the competition. We wish you good luck and hope that your participation will be successful!",
    "Извините, ваша транзакция не удалась. Пожалуйста, внимательно проверьте введенные данные и повторите попытку.": "Sorry, your transaction failed. Please check the entered information carefully and try again.",
    "Поздравляем": "Congratulations",
    "Ошибка": "Error",
    "Готово!": "Ok",
    "Ошибка подключения": "Connection error",
    "Извините, мы не можем сейчас подключиться к вашему кошельку из-за проблем с сетью или конфигурацией.": "Sorry, we can't connect to your wallet right now due to network or configuration issues.",
    "за билет": "per ticket",
    "Количество билетов": "Count of tickets",
    "Вы можете выбрать количество билетов с номерами, которые будут присвоены случайным образом.": "You can select the number of tickets with numbers that will be assigned randomly.",
    "Купить билет за": "Buy a ticket for",
    "Ваш счастливый номер": "Your lucky number",
    "Выберите номер из 1-777 и получите билет на свой счастливый номер.": "Select a number from 1-777 and receive a ticket to your lucky number.",
    "Поиск номера билета": "Search for ticket number",
    "Только доступные": "Only available",
    "Неверный номер, попробуйте искать до номера 777.": "Invalid number, search to number 777.",
    "Всего билетов": "Total tickets",
    "Доступный": "Accessible",
    "Проданный": "Sold",
    "Реферальная программа": "Referral program",
    "Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки. Кроме того, если вы продали не менее 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.": "Each member who invites a new player receives 5% of their purchase amount. In addition, if you sell at least 5 tickets, you receive 10% of the purchase price of each invited player.",
    "Ваш идентификатор": "Your ID",
    "Поделиться моим кодом": "Share my code",
    "Мои друзья" : "My friends",
    "Приглашенных друзей": "Invited friends",
    "Заработок от друзей": "Earnings from friends",
    "Ваш пригласитель": "Your inviter",
    "Поделиться": "Share",
    "Поделитесь своим реферальным идентификатором в социальных сетях": "Share your referral ID on social networks",
    "Мои билеты": "My tickets",
    "Активные билеты": "Active tickets",
    "Активный": "Active",
    "Адрес кошелька": "Wallet address",
    "Цена": "Price",
    "Выигрыш": "Winning",
    "Неактивный": "Inactive",
    "Предыдущие билеты": "Previous tickets",
    "Правила": "Rules",
    "ЧАВО": "FAQ",
    "Условия пользователя": "User Terms",
    "Политика конфиденциальности": "Privacy Policy",
    "Сотрудничества": "Cooperation",
    "Мы в социальных сетях": "We are on social networks",
    "Социальные сети": "Social media",
    "Участие в лотерее":"Participation in the lottery",
    "Для участия необходимо зарегистрироваться через официальный смарт-контракт лотереи.":"To participate, you must register through the official smart contract of the lottery.",
    "Покупка билета осуществляется посредством перевода установленной суммы в криптовалюте на адрес смарт-контракта.": "The purchase of a ticket is carried out by transferring a set amount in cryptocurrency to the address of the smart contract.",
    "Каждому участнику выдается уникальный идентификатор билета, который записывается в блокчейн.": "Each participant is given a unique ticket ID, which is recorded on the blockchain.",
    "Стоимость билета": "Ticket price",
    "Цена одного билета составляет фиксированную сумму, указанную в условиях лотереи.": "The price of one ticket is a fixed amount specified in the lottery conditions.",
    "Покупка билетов возможна только в поддерживаемых криптовалютах.": "Ticket purchases are only possible in supported cryptocurrencies.",
    "В случае изменения курса криптовалют, стоимость билета остается неизменной.": "If the cryptocurrency exchange rate changes, the ticket price remains unchanged.",
    "Период проведения": "Period",
    "Продажа билетов начинается с момента активации смарт-контракта.": "Ticket sales begin from the moment the smart contract is activated.",
    "Окончание продажи билетов и дата розыгрыша устанавливаются в условиях лотереи.": "The end of ticket sales and the drawing date are established in the lottery conditions.",
    "Розыгрыш проводится автоматически после завершения продажи билетов.": "The drawing will take place automatically after ticket sales are completed.",
    "Прозрачность и честность": "Transparency and Honesty",
    "Смарт-контракт лотереи полностью прозрачен и доступен для проверки в открытом блокчейне.": "The lottery smart contract is completely transparent and verifiable on an open blockchain.",
    "Розыгрыш победителей осуществляется с использованием алгоритмов генерации случайных чисел, интегрированных в смарт-контракт.": "The winners are drawn using random number generation algorithms integrated into the smart contract.",
    "Все данные о билетах, участниках и результатах фиксируются в блокчейне и не подлежат изменению.": "All data about tickets, participants and results are recorded in the blockchain and cannot be changed.",
    "Призовой фонд": "Prize fund",
    "Призовой фонд формируется из средств, собранных от продажи билетов.": "The prize fund is formed from funds collected from ticket sales.",
    "Размер выигрышей и количество призовых мест указаны в условиях лотереи.": "The size of winnings and the number of prize places are indicated in the lottery conditions.",
    "Комиссия организаторов  автоматически вычитается из призового фонда и отображается в условиях лотереи.": "The organizers' commission is automatically deducted from the prize fund and displayed in the lottery conditions.",
    "Определение победителей": "Determination of the winners",
    "Победители определяются на основе алгоритма случайной выборки среди уникальных идентификаторов билетов.": "Winners are determined based on a random selection algorithm among unique ticket identifiers.",
    "Результаты розыгрыша фиксируются в блокчейне и доступны для проверки.": "The results of the drawing are recorded in the blockchain and are available for verification.",
    "В случае отсутствия победителя по каким-либо техническим причинам, средства участников возвращаются автоматически.": "If there is no winner for any technical reasons, the participants' funds will be returned automatically.",
    "Выплата выигрышей": "Payout of winnings",
    "Выигрыши автоматически перечисляются на адреса криптовалютных кошельков, с которых были куплены билеты.": "Winnings are automatically transferred to the addresses of the cryptocurrency wallets from which the tickets were purchased.",
    "Участники несут ответственность за доступ к своему кошельку. В случае утери доступа организаторы не несут ответственности за невыданный выигрыш.": "Members are responsible for access to their wallet. In case of loss of access, the organizers are not responsible for undistributed winnings.",
    "Ограничения и ответственность": "Limitations and liability",
    "Участие запрещено лицам, проживающим в юрисдикциях, где участие в лотереях запрещено законом.": "Participation is prohibited to persons residing in jurisdictions where participation in the lotteries is prohibited by law.",
    "Организаторы не несут ответственности за технические сбои в сети блокчейна, влияющие на процесс лотереи.": "The organizers are not responsible for technical failures in the blockchain network that affect the lottery process.",
    "Участие в лотерее подразумевает согласие с указанными правилами.": "Participation in the lottery implies agreement with these rules.",
    "Прочие условия": "Other conditions",
    "Организаторы оставляют за собой право изменять условия лотереи только до начала продажи билетов.": "The organizers reserve the right to change the conditions of the lottery only before the start of ticket sales.",
    "Все изменения фиксируются в обновленной версии смарт-контракта, доступной для проверки.": "All changes are recorded in an updated version of the smart contract, available for verification.",
    "Как приобрести билет?": "How to purchase a ticket?",
    'Откройте наш сайт и нажмите кнопку "Подключить кошелек".Поддерживаются популярные кошельки, такие как MetaMask, Trust Wallet .Выберите нужный уровень и количество билетов.Убедитесь, что у вас на кошельке достаточно средств в поддерживаемой криптовалюте (например USDT).Переведите указанную сумму на адрес смарт-контракта.После успешной транзакции вы получите уникальный идентификатор билета, записанный в блокчейн.': 'Open our website and click the "Connect wallet" button. Popular wallets are supported, such as MetaMask, Trust Wallet. Select the desired level and number of tickets. Make sure that you have enough funds in your wallet in a supported cryptocurrency (for example USDT). Transfer the specified amount to smart contract address. After a successful transaction, you will receive a unique ticket identifier recorded in the blockchain.',
    "Какая стоимость билета?": "What is the ticket price?",
    "Стоимость одного билета фиксирована и указана на странице текущего розыгрыша. Она оплачивается в поддерживаемой криптовалюте USDT, например: для WEB3 1 билет - 25 USDT Для ТГ Стартовый уровень «Майнинг Удачи»  1 билет - 1 USDT Стандарт уровень «Золотой Шанс»  1 билет - 5 USDT Премиум уровень «Крипто Сокровище»   1 билет - 10 USDT. Или другая актуальная цена билета, установленный для конкретного раунда. Убедитесь, что на вашем кошельке достаточно средств, чтобы оплатить билет и покрыть возможные транзакционные комиссии.": 'The cost of one ticket is fixed and indicated on the page of the current drawing. It is paid in the supported cryptocurrency USDT, for example: for WEB3 1 ticket - 25 USDT For TG Starting level "Mining Luck" 1 ticket - 1 USDT Standard level "Golden Chance" 1 ticket - 5 USDT Premium level "Crypto Treasure" 1 ticket - 10 USDT. Or another current ticket price set for a specific round. Make sure that your wallet has enough funds to pay for the ticket and cover possible transaction fees.',
    "Сколько я могу приобрести билетов?": "How many tickets can I purchase?",
    "Количество билетов, которые вы можете приобрести, не ограничено. Вы можете покупать столько билетов, сколько пожелаете, что увеличивает ваши шансы на выигрыш.Однако убедитесь, что вы соблюдаете условия и правила участия, указанные для конкретного розыгрыша.": "There is no limit to the number of tickets you can purchase. You can buy as many tickets as you want, which increases your chances of winning. However, make sure you follow the terms and conditions of participation specified for a particular draw.",
    "Как часто происходит розыгрыш?": "How often does the draw take place?",
    "Как только наши участники приобретут 777 билетов, мы немедленно объявим победителей.": "Once our participants have purchased 777 tickets, we will immediately announce the winners.",
    "Как распределяются выигрыш?": "How are the winnings distributed?",
    "Джекпот:  - 100* Х (х-стоимость билета), 125 выигрышей - 1*Х (х-стоимость билета), 62 выигрыша - 2*Х (х-стоимость билета), 16 выигрышей - 4*Х (х-стоимость билета)": "Jackpot: - 100* X (x-ticket price), 125 wins - 1*X (x-ticket price), 62 wins - 2*X (x-ticket price), 16 wins - 4*X (x-ticket price)",
    "Как выиграть Джекпот?": "How to win the Jackpot?",
    "Для выигрыша Джекпота, участнику необходимо купить билет и его номер должен совпасть с определенным выигрышными номером, который выбирается случайным образом при каждом розыгрыше. Частота Розыгрышей Джекпота: Джекпот разыгрывается после продажи каждых 777 билетов.": "To win the Jackpot, a participant must purchase a ticket and its number must match a specific winning number, which is randomly selected at each draw. Jackpot Draw Frequency: The Jackpot is drawn after every 777 tickets are sold.",
    "Как выиграть Мегаджекпот?": "How to win Mega Jackpot?",
    "С каждого розыгрыша 3% от общей суммы взносов отправляются на специальный счет Мега Джекпота. Растущий Выигрыш: В течение 30 дней мы аккумулируем средства на счете Мега Джекпота. По истечении 30 дней наступает самое ожидаемое мгновение - Грандиозный Розыгрыш Мега Джекпота!": "From each draw, 3% of the total amount of contributions is sent to a special Mega Jackpot account. Growing Win: For 30 days, we accumulate funds in the Mega Jackpot account. After 30 days, the most anticipated moment comes - the Grand Mega Jackpot Draw!",
    "Какое реферальное вознаграждение?": "What is the referral reward?",
    "Каждый участник, который привлекает нового игрока, получает 5% от суммы покупки одного билета  за каждый привлеченный билет. Активный приглашающий участник - это тот, кто продал не менее 5 билетов,получает 10 %  от суммы покупки. Реферальные уровни: На первом уровне каждый участник получает 10% от выигрыша каждого приведенного другом участника. На втором уровне каждый участник получает 5% от выигрыша каждого участника, приглашенного его прямым рефералом.На третьем уровне каждый участник получает 2% от выигрыша каждого участника, приглашенного его прямым или косвенным рефералом на втором уровне.": "Each member who brings a new player gets 5% of the purchase amount of one ticket for each attracted ticket. An active inviting member is one who has sold at least 5 tickets, gets 10% of the purchase amount. Referral levels: At the first level, each member gets 10% of the winnings of each member brought by a friend. At the second level, each member gets 5% of the winnings of each member invited by his direct referral. At the third level, each member gets 2% of the winnings of each member invited by his direct or indirect referral at the second level.",
    "Какие бонусы можно получить?": "What bonuses can you get?",
    "Бонус за привлечение рефералов: Игрок получит дополнительный бонус в размере 5 USDT, за привлечение 10 рефералов. Бонус за продажу билетов: Игрок получит дополнительный бонус в размере 100 USDT, за продажу 50 билетов. Бонус за победу в лотерее: Игрок получит дополнительный бонус в размере 50 USDT, при выигрыше в 3 розыгрышах подряд. Бонус за участие в лотерее: Игрок может получить дополнительный бонус в размере 5 USDT ,если купит 10 билетов.": "Referral Bonus: Player will receive an additional bonus of 5 USDT for attracting 10 referrals. Ticket Sales Bonus: Player will receive an additional bonus of 100 USDT for selling 50 tickets. Lottery Win Bonus: Player will receive an additional bonus of 50 USDT for winning 3 draws in a row. Lottery Participation Bonus: Player can receive an additional bonus of 5 USDT if he/she buys 10 tickets.",
    "Личная безопасность в цифровом мире": "Personal safety in the digital world",
    "Безопасность персональных данных": "Security of personal data",
    "Никогда не делитесь личной информацией (номер телефона, адрес, данные банковских карт) с незнакомыми людьми или подозрительными сайтами. Используйте сложные пароли для всех аккаунтов и изменяйте их регулярно. Включите двухфакторную аутентификацию (2FA) на всех платформах, где это возможно.": "Never share personal information (phone number, address, bank card details) with strangers or suspicious sites. Use complex passwords for all accounts and change them regularly. Enable two-factor authentication (2FA) on all platforms where possible.",
    "Осторожность в социальных сетях": "Be careful on social media",
    "Не публикуйте информацию, которая может раскрыть ваше местоположение или другие личные данные. Настройте параметры конфиденциальности, чтобы ограничить доступ к вашим публикациям. Будьте внимательны к фишинговым ссылкам, которые могут приходить через сообщения.": "Don't post information that could reveal your location or other personal data. Adjust your privacy settings to limit access to your posts. Be careful of phishing links that may arrive via messages.",
    "Защита устройств": "Device protection",
    "Используйте антивирусное программное обеспечение и регулярно обновляйте его.Не устанавливайте приложения из недоверенных источников.Защитите устройства паролем, PIN-кодом или биометрией.": "Use antivirus software and update it regularly. Do not install applications from untrusted sources. Protect your device with a password, PIN code or biometrics.",
    "Безопасность в сети": "Online Security",
    'Избегайте использования общедоступных Wi-Fi сетей без VPN.Убедитесь, что сайты, на которых вы вводите личные данные, используют защищенное соединение (адрес сайта должен начинаться с "https://").Регулярно проверяйте настройки конфиденциальности в своих аккаунтах.': 'Avoid using public Wi-Fi networks without a VPN. Make sure that sites where you enter personal data use a secure connection (the site address should start with "https://"). Regularly check the privacy settings in your accounts.',
    "Финансовая безопасность": "Financial security",
    'Проверяйте транзакции и следите за выписками по банковским счетам. Никогда не переводите деньги незнакомым людям или в ответ на подозрительные сообщения. Будьте внимательны к схемам мошенничества, особенно связанным с "легким заработком".': 'Check your transactions and monitor your bank statements. Never transfer money to strangers or in response to suspicious messages. Be alert to scams, especially those involving "easy money."',
    "Личная безопасность оффлайн": "Personal safety offline",
    "Не делитесь своими планами с посторонними людьми. Сообщайте близким о своем местонахождении, особенно в случае встреч с незнакомцами. В ситуациях, когда вы чувствуете угрозу, немедленно обращайтесь в правоохранительные органы.": "Do not share your plans with strangers. Inform your loved ones about your location, especially in case of meetings with strangers. In situations where you feel threatened, immediately contact law enforcement agencies.",
    "Психологическая безопасность": "Psychological safety",
    "Обучение и осведомленность": "Training and awareness",
    "Регулярно обновляйте свои знания о современных угрозах и способах защиты. Делитесь этими знаниями с близкими, особенно с детьми и пожилыми людьми. Участвуйте в обучающих мероприятиях или вебинарах, посвященных кибербезопасности и личной защите.": "Regularly update your knowledge about modern threats and ways to protect yourself. Share this knowledge with your loved ones, especially children and the elderly. Participate in educational events or webinars on cybersecurity and personal protection.",
    "Вы несете полную ответственность за сохранность Ваших кошельков и отвечаете за все транзакции по Вашим кошелькам.  Cryptolottorey7777 ни при каких обстоятельствах  не несет ответственности за любые потери или последствия, вызванные санкционированным или несанкционированным использованием средств или учетных данных Ваших кошельков, включая, помимо прочего, потерю личных средств, разглашение информации, выдачу информации, согласие или подчинение различным правилам и соглашениям путем клика на сайте.Мы не несем никакой ответственности или обязательств за ошибки, вирусы, троянские кони или подобные программы, которые могут быть переданы на веб-сайт или через него.": "You are solely responsible for the safety of your wallets and are responsible for all transactions on your wallets. Cryptolottorey7777 will under no circumstances be liable for any loss or consequences caused by authorized or unauthorized use of funds or credentials of your wallets, including but not limited to loss of personal funds, disclosure of information, release of information, consent to or submission to various rules and agreements by clicking on the site. We do not accept any responsibility or liability for errors, viruses, Trojan horses or similar programs that may be transmitted to or through the website.",
    "Не отвечайте на оскорбительные или угрожающие сообщения. Используйте функции блокировки и жалоб на платформе, если сталкиваетесь с агрессией. Если вы чувствуете себя подавленным или запуганным, обратитесь за поддержкой к друзьям, близким или специалистам.": "Don’t respond to offensive or threatening messages. Use the platform’s block and report functions if you encounter aggression. If you feel overwhelmed or intimidated, seek support from friends, family, or professionals."





}