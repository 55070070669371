import AuthLayouth from "../auth/AuthLayouth";
import ErrorPage from "../pages/ErrorPage";
import Jackpot from "../pages/Jackpot";
import MegaJackpot from "../pages/MegaJackpot";
import Friends from "../pages/Friends";
import HowToPlay from "../pages/HowToPlay";
import Profile from "../pages/Profile";
import More from "../pages/More";
import Rules from "../pages/Rules";
import FAQ from "../pages/FAQ";
import Social from "../pages/Social";
import UserTerms from "../pages/UserTerms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Cooperation from "../pages/Cooperation";


interface RouteProps {
    path: string;
    component: any;
    exact?: boolean;
}

export const allRoutes: Array<RouteProps> = [
    { path: "/", component: AuthLayouth },
    { path: "/jackpot", component: Jackpot },
    { path: "/friends", component: Friends },
    { path: "/how-to-play", component: HowToPlay },
    { path: "/profile", component: Profile },
    { path: "/more", component: More },
    { path: "/rules", component: Rules },
    { path: "/faq", component: FAQ },
    { path: "/social-media", component: Social },
    { path: "/user-terms", component: UserTerms },
    { path: "/privacy-policy", component: PrivacyPolicy },
    { path: "/cooperation", component: Cooperation },
    { path: "/:ref", component: AuthLayouth },  // This handles dynamic address
    { path: "*", component: ErrorPage },
];