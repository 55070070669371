import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import ConfirmIcon from "../assets/icons/confirm-icon.svg";
import ErrorIcon from "../assets/icons/error-icon.svg";

function CompleteModal({infoModalOpen, setInfoModalOpen, isError, errorTitle, errorContent}:any) {

    const {t} = useTranslation();

    return (
        <Modal
            open={infoModalOpen}
            footer={null}
            closeIcon={false}
            className="info-modal"
            centered
        >
            <div className="info-modal-body">
                {
                    !isError ?
                        <>
                            <img src={ConfirmIcon} alt="icon" width="59" height="59"/>
                            <div className="success-header">{t('Поздравляем')}!</div>
                            <div className="success-text">
                                {t('Ваша транзакция успешно выполнена, и теперь у вас есть билет на участие в конкурсе. Желаем вам удачи и надеемся, что ваше участие будет успешным!')}!
                            </div>
                            <button className="success-button" onClick={() => setInfoModalOpen(false)}>
                                {t('Готово!')}
                            </button>
                        </> :
                        <>
                            <img src={ErrorIcon} alt="icon" width="59" height="59"/>
                            <div className="success-header">{!errorTitle ?
                                t('Ошибка'):
                                t('Ошибка подключения')}
                            </div>
                            <div className="success-text">
                                {!errorContent ?
                                    t('Извините, ваша транзакция не удалась. Пожалуйста, внимательно проверьте введенные данные и повторите попытку.'):
                                    t('Извините, мы не можем сейчас подключиться к вашему кошельку из-за проблем с сетью или конфигурацией.')}
                            </div>
                            <button className="success-button" onClick={() => setInfoModalOpen(false)}>
                                {t('Готово!')}
                            </button>
                        </>
                }
            </div>
        </Modal>
    )
}

export default CompleteModal