import React from "react";
import {useTranslation} from "react-i18next";

function FooterItem (props: any) {
    const {t} = useTranslation();

    return (
        <div className='footer-item' onClick={props.func}>
            {props.activeIconName === props.name?
                <img src={props.selectedIcon} alt="icon" width='30' height='30'/> :
                <img src={props.defaultIcon} alt="icon" width='30' height='30'/>
            }
            <div className={props.activeIconName !== props.name? 'footer-item_name' : "footer-item_name is-active"}>
                {t(props.name)}
            </div>
        </div>
    )
}

export default FooterItem