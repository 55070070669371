import React from "react";
import leftArrow from "../assets/icons/left-arrow.svg";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {Collapse} from "antd";
import CollapseIcon from "../assets/icons/collapse-icon.svg"
import {FaqItems} from "../constants/const";

function FAQ () {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="faq">
            <div className="jackpot_title">
                <img src={leftArrow}
                     alt="icon"
                     width={32}
                     height={32}
                     onClick={() => {navigate('/more')}}
                />
                <span>{t('ЧАВО')}</span>
            </div>
            {FaqItems.map((item, index) => {
                return (
                    <Collapse
                        items={[
                            {
                                key: index,
                                label:
                                    <div className="collapse-item">
                                        <div className="faq-collapse-item-text">{t(item.title)}</div>
                                    </div>,
                                children: <span className="faq-collapse-item-content">{t(item.content)}</span>
                            }]}
                        rootClassName="faq-collapse"
                        key={index}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={() => <img src={CollapseIcon} alt="icon"/>}
                    />
                )
            })
            }

        </div>
    )
}

export default FAQ