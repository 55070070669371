import React from "react";
import leftArrow from "../assets/icons/left-arrow.svg";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {socialMediaData} from "../constants/const";
import InfoItem from "../components/InfoItem";

function Social() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (item: number) => {
        switch (item) {
            case 0:
                window.open("https://www.whatsapp.com/", "_blank");
                break;
            case 1:
                window.open("https://www.facebook.com/", "_blank");
                break;
            case 2:
                window.open("https://x.com/", "_blank");
                break;
            case 3:
                window.open("https://telegram.org/", "_blank");
                break;
            case 4:
                window.open("https://www.instagram.com/", "_blank");
                break;
            case 5:
                window.open("https://www.youtube.com/", "_blank");
                break;
            case 6:
                window.open("https://discord.com/", "_blank");
                break;
            default:
                navigate("*");
        }
    };

    return (
        <div className="social">
            <div className="jackpot_title">
                <img src={leftArrow}
                     alt="icon"
                     width={32}
                     height={32}
                     onClick={() => {
                         navigate('/more')
                     }}
                />
                <span>{t('Социальные сети')}</span>
            </div>
            {socialMediaData.map((item: any, index) => {
                return (
                    <InfoItem
                        key={index}
                        index={index}
                        item={item}
                        func={() => handleNavigate(index)}
                    />
                )
            })}

        </div>
    )
}

export default Social