import React from "react";
import Play from "../assets/icons/video-play.svg";
import Close from "../assets/icons/close.svg";
import {useTranslation} from "react-i18next";

function VideoItem(props: any) {
    const {t} = useTranslation();

    return (
        <>
            {props.size === props.index ?
                <div className="video-item-open">
                    <div className="video-header">
                        <div className="video-header_left">
                            <img src={props.image} alt="icon" width="40" height="40"/>
                            <span>{t(props.text)}</span>
                        </div>
                        <img src={Close} onClick={props.close} alt="icon"/>
                    </div>
                    <video controls autoPlay>
                        <source
                            src={props.src}
                            type="video/mp4"
                        />
                        {t('Ваш браузер не поддерживает HTML-видео.')}
                    </video>

                </div> :
                <div className="video-item" onClick={props.func}>
                    <div className="video-item_top">
                        <img src={props.image} alt="icon" width="67" height="67"/>
                        <img src={Play} alt="icon" width="32" height="32"/>
                    </div>
                    <div className="video-item_bottom">
                        {props.text}
                    </div>
                </div>

            }
        </>
    )
}

export default VideoItem