import React from "react";
import RightArrow from "../assets/icons/right-arrow.svg";
import {useTranslation} from "react-i18next";

function InfoItem ({item,index,func,isLine}:any) {
    const {t} = useTranslation()

    return (
        <div key={index} onClick={func}>
            {isLine && index === 2 && <div className="custom-line"/>}
            <div className="info-item" >
                <div className="info-item_left">
                    <img src={item.icon} alt="icon" width={32} height={32}/>
                    <span>{t(item.name)}</span>
                </div>
                <img src={RightArrow} alt="icon" width={24} height={24}/>
            </div>
        </div>
    )
}

export default InfoItem