import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import MainButton from "../components/MainButton";
import leftArrow from "../assets/icons/left-arrow.svg"
import JackpotImage from "../assets/images/2500-usdt-mini.svg";
import UsdtIcon from "../assets/icons/usdt-icon.svg";
import IncrementIcon from "../assets/icons/increment-icon.svg";
import DecrementIcon from "../assets/icons/decrement-icon.svg";
import SearchIcon from "../assets/icons/search-icon.svg"
import ButTicketIcon from "../assets/icons/buy-ticket-icon.svg";
import TicketIcon from "../assets/icons/ticket-icon.svg";
import TicketDisabledIcon from "../assets/icons/ticket-disabled-icon.svg";
import { Input, Spin, Switch } from "antd";
import CompleteModal from "../components/CompleteModal";
import { approveUSDT, buyTickets, getSoldTicketCount, getTicketPrice, getTicketsStatus } from "../web3";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { ethers } from 'ethers';
import { ABI } from "../ABI/ABI";
import { mUSDTABI } from "../ABI/mUSDTABI";


function Jackpot(data: any) {
    const { getWalletProvider }: any = useWeb3Modal(); // Получаем Web3Modal провайдер
    const [accessibleTicketsCount, setAccessibleTicketsCount] = useState();
    const [soldTicketsCount, setSoldTicketsCount] = useState();
    const [inputValue, setInputValue] = useState(1);
    const [totalCount, setTotalCount] = useState(25);
    const [allTickets, setAllTickets] = useState<any>([]);
    const [isChecked, setIsChecked] = useState<Boolean>(true);
    const [isNotTicket, setIsNotTicket] = useState<Boolean>(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState<any>(false);
    const [allTicketsCount, setAllTicketsCount] = useState([]);
    const [ticketPrice, setTicketPrice] = useState();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const mUSDTAddress = "0x3051ee640D0A935BD181b0b81dde9d80998f4c15";
    const contractAddress = "0x7283B47f40641F0C977F12bB87D5c1bEf0F1821E";

    console.log("HHHHH", data);


    useEffect(() => {
        getTicketPrice().then((res: any) => {
            let price: any = Number(res) / 10 ** 18
            setTicketPrice(price)
        })
    }, [ticketPrice])


    const generateArray = () => {
        let newArr: any = []
        getTicketsStatus().then(arr => {
            for (let i = 0; i < arr.length; i++) {
                let item = {
                    ticket_number: [i + 1].toString(),
                    is_active: !arr[i]
                }
                newArr.push(item)
            }
            setAllTickets(newArr);
        })
    }



    useEffect(() => {
        generateArray()
        getSoldTicketCount().then((res: any) => {
            setSoldTicketsCount(res[0]);
            setAllTicketsCount(res[1])
            let accessibleCount: any = res[1] - res[0];
            setAccessibleTicketsCount(accessibleCount);
        })
    }, [isChecked]);

    const handleInputValue = (e: any) => {
        let inputCount: any = Number(e.target.value)
        if (inputCount <= allTicketsCount && inputCount >= 0) {
            let totalPriceInUsdt = inputCount * 25
            setInputValue(inputCount)
            setTotalCount(totalPriceInUsdt)
        }
    };
    const handleDecrement = () => {
        if (Number(inputValue) > 1) {
            let decrementValue: any = Number(inputValue) - 1;
            let totalPriceInUsdt = decrementValue * 25
            setInputValue(decrementValue);
            setTotalCount(totalPriceInUsdt)
        }
    };
    const handleIncrement = () => {
        if (Number(inputValue) < Number(allTicketsCount)) {
            let incrementValue: any = Number(inputValue) + 1;
            let totalPriceInUsdt = incrementValue * 25
            setInputValue(incrementValue);
            setTotalCount(totalPriceInUsdt)
        }
    };




    const handleBuyJackpotTicket = async () => {
        try {
            setLoading(true);
            console.log(inputValue);

            const approveRes = await approveUSDT(totalCount, data.provider)
            setTimeout(async () => {
                const buyRes = await buyTickets(0, inputValue, data?.provider)
                console.log("buy-ticket", buyRes);
                setLoading(false);
                setInfoModalOpen(true);
                getTicketPrice()
            }, 3000);

        } catch (error) {
            alert(error)
            setLoading(false);
            console.error("Error during purchase process:", error);
            setLoading(false);
        }
        let status = true
        if (status) {
            setInputValue(1)
            setTotalCount(25)
            setIsError(false)
            setInfoModalOpen(true);
        }
        else {
            setInputValue(1)
            setTotalCount(25)
            setIsError(true)
            setInfoModalOpen(true);
        }

    };

    const buyLuckyTicket = async (ticket: any) => {
        try {
            setLoading(true);

            const approveRes = await approveUSDT(totalCount, data?.provider)
            console.log("approveUSDT result:", approveRes);
            setTimeout(async () => {
                const buyRes = await buyTickets(ticket?.ticket_number, 0, data?.provider)
                console.log("buy-ticket", buyRes);
                setLoading(false);
                setInfoModalOpen(true);
                getTicketPrice()
                // window.location.reload()
            }, 3000);

        } catch (error) {
            setLoading(false);
            console.error("Error during purchase process:", error);
            setLoading(false);
        }

    };

    const onSwitchChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
        if (checked) {
            console.log("allTICKETS", allTickets)
            let availableTickets = allTickets.filter(((ticket: any) => ticket.is_active === true))
            setAllTickets(availableTickets)
        } else {
            setIsChecked(!isChecked);
        }
    };

    const handleSearchNumber = (event: any) => {
        console.log("number", event.target.value)
        let number = event.target.value
        if (Number(number) >= 0 && Number(number) <= 777) {
            setIsNotTicket(false)
            if (number.length) {
                console.log("number.length", number.length)
                // let allNumbers = generateArray()
                let newArr: any = []
                getTicketsStatus().then(arr => {

                    for (let i = 0; i < arr.length; i++) {
                        let item = {
                            ticket_number: [i + 1].toString(),
                            is_active: !arr[i]
                        }
                        newArr.push(item)
                    }
                    let searchNumber = newArr.filter((ticket: any) => ticket.ticket_number === number)
                    console.log("searchNumber", searchNumber)
                    setAllTickets(searchNumber)
                })
            } else setIsChecked(!isChecked)
        } else setIsNotTicket(true)
    }


    const approveTokens = async () => {
        approveUSDT(10, data.provider)
        // buyTickets(0,1,data?.provider)
    };



    return (
        <>
            {loading ? <Spin fullscreen={true} size="large" /> :
                <div className="jackpot">
                    <div className="jackpot_title">
                        <img src={leftArrow} alt="icon" width={32} height={32} onClick={() => {
                            navigate('/')
                        }} />
                        <span>{t('Джекпот')}</span>
                    </div>
                    <div className="jackpot_info">
                        <div className="jackpot_info__price">
                            <img src={UsdtIcon} alt="icon" />
                            <span className="jackpot_info__price___unit-price">{ticketPrice} USDT</span>
                            <span className="jackpot_info__price___text">{t('за билет')}</span>
                        </div>
                        <div className="jackpot_info__tickets-count">
                            <div className="jackpot_info__tickets-count___item">
                                <div className="jackpot_info__tickets-count___item____title">{t('Всего билетов')}</div>
                                <div className="jackpot_info__tickets-count___item____count">{allTicketsCount}</div>
                            </div>
                            <div className="jackpot_info__tickets-count___item">
                                <div className="jackpot_info__tickets-count___item____title">{t('Доступный')}</div>
                                <div className="jackpot_info__tickets-count___item____count">{accessibleTicketsCount}</div>
                            </div>
                            <div className="jackpot_info__tickets-count___item">
                                <div className="jackpot_info__tickets-count___item____title">{t('Проданный')}</div>
                                <div className="jackpot_info__tickets-count___item____count">{soldTicketsCount}</div>
                            </div>
                        </div>
                        <img src={JackpotImage} alt="icon" className="jackpot_info__logo" />
                    </div>
                    <div className="buy-ticket-block">
                        <div className="buy-ticket-block_header">
                            <div className="buy-ticket-block_header__title">
                                {t('Количество билетов')}
                            </div>
                            <div className="buy-ticket-block_header__subtitle">
                                {t('Вы можете выбрать количество билетов с номерами, которые будут присвоены случайным образом.')}
                            </div>
                        </div>
                        <div className="ticket-count-input">
                            <img src={DecrementIcon} alt="icon" onClick={handleDecrement} />
                            <input
                                type="text"
                                className="ticket-count-input_count"
                                value={inputValue}
                                onChange={handleInputValue}
                            />
                            <img src={IncrementIcon} alt="icon" onClick={handleIncrement} />
                        </div>
                        <div className="buy-ticket-block_btn">
                            <MainButton
                                icon={ButTicketIcon}
                                text={'Купить билет за'}
                                price={totalCount}
                                func={handleBuyJackpotTicket}
                            />
                        </div>
                    </div>
                    <div className="choose-number-block">
                        <div className="buy-ticket-block_header">
                            <div className="buy-ticket-block_header__title">
                                {t('Ваш счастливый номер')}
                            </div>
                            <div className="buy-ticket-block_header__subtitle">
                                {t('Выберите номер из 1-777 и получите билет на свой счастливый номер.')}
                            </div>
                        </div>
                        <div className="choose-number-block_filter">
                            <Input
                                className="choose-number-block_filter__search-ticket-number"
                                placeholder={t("Поиск номера билета")}
                                prefix={<img src={SearchIcon} alt="icon" />}
                                onInput={handleSearchNumber}
                            />
                            <div className="choose-number-block_filter__switch">
                                <span>{t('Только доступные')}</span>
                                <Switch
                                    onChange={onSwitchChange}
                                    className="switcher"
                                />

                            </div>
                        </div>
                        <div className="choose-number-block_tickets">
                            {!isNotTicket ?
                                <>{allTickets?.map((ticket: any) => {
                                    return (
                                        <div className="choose-number-block_tickets__item" key={ticket.ticket_number} onClick={() => buyLuckyTicket(ticket)}>
                                            {ticket.is_active ?
                                                <img src={TicketIcon} alt="Snow" width={40} height={40} /> :
                                                <img src={TicketDisabledIcon} alt="Snow" width={40} height={40} />}
                                            <div
                                                className={ticket.is_active ? "choose-number-block_tickets__item___number" : "choose-number-block_tickets__item___number disabled"}
                                            >
                                                {ticket.ticket_number}
                                            </div>
                                        </div>
                                    )
                                })}</> :
                                <div className="choose-number-block_tickets__error">
                                    <span>{t('Неверный номер, попробуйте искать до номера 777.')}</span>
                                </div>}
                        </div>
                    </div>
                    <CompleteModal
                        infoModalOpen={infoModalOpen}
                        setInfoModalOpen={setInfoModalOpen}
                        isError={isError}
                    />

                </div>}
        </>
    )
}

export default Jackpot