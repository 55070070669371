import React from "react";
import {Collapse} from "antd";
import {useTranslation} from "react-i18next";
import CollapseIcon from "../assets/icons/collapse-icon.svg";
import TicketPinkIcon from "../assets/icons/ticket-pink-icon.svg"


function Ticket({ticket}: any) {
    const {t} = useTranslation()
    console.log("ticket", ticket)
    return (
        <div className="ticket">
            <Collapse
                items={[
                    {
                        label:
                            <div className="ticket-collapse-item">
                                <div className="ticket-collapse-item_left">
                                    <img src={TicketPinkIcon} alt="icon" width={32} height={32}/>
                                    <span className="ticket-collapse-item_left__ticket-number">
                                        <span style={{fontStyle: "italic"}}>N</span>
                                        {ticket?.ticket_number}
                                    </span>
                                </div>
                                {ticket?.is_active ?
                                    <div className="ticket-collapse-item_right-active">
                                        {t('Активный')}
                                    </div> :
                                    <div className="ticket-collapse-item_right-not-active">
                                        {t('Неактивный')}
                                    </div>
                                }
                            </div>,
                        children:
                            <div className="ticket-collapse-item-content">
                                <div className="ticket-collapse-item-content_item">
                                    <div className="ticket-collapse-item-content_item__left">{t('Адрес кошелька')}:
                                    </div>
                                    <div
                                        className="ticket-collapse-item-content_item__right">{ticket?.wallet_address.slice(0, 5) + '...' + ticket?.wallet_address.slice(-5)}</div>
                                </div>
                                <div className="ticket-collapse-item-content_item">
                                    <div className="ticket-collapse-item-content_item__left">{t('Цена')}:</div>
                                    <div className="ticket-collapse-item-content_item__right">{ticket?.price} USDT</div>
                                </div>
                                <div className="ticket-collapse-item-content_item">
                                    <div className="ticket-collapse-item-content_item__left">{t('Выигрыш')}:</div>
                                    {ticket?.winning.length ?
                                        <div className="ticket-collapse-item-content_item__right">
                                            {ticket?.winning} USDT
                                        </div>:
                                        <div className="ticket-collapse-item-content_item__right"> - </div>
                                    }
                                </div>
                            </div>
                    }]}
                rootClassName="ticket-collapse"
                expandIconPosition={"end"}
                bordered={false}
                expandIcon={() => <img src={CollapseIcon} alt="icon"/>}
            />
        </div>
    )
}

export default Ticket