import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Ticket from "../components/Ticket";

function Profile () {
    const [isActive, setIsActive] = useState(false);
    const [jackpotTickets, setJackpotTickets] = useState<any>([]);
    const [megaJackpotTickets, setMegaJackpotTickets] = useState<any>([]);
    const [activeTickets, setActiveTickets] = useState<any>([]);
    const [inActiveTickets, setInActiveTickets] = useState<any>([]);
    const {t} = useTranslation();

    useEffect(() => {
        const jackpotData = [
            {
                ticket_number: "42",
                is_active: true,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "25",
                winning: "225"
            },{
                ticket_number: "48",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "50",
                winning: "150"
            },{
                ticket_number: "21",
                is_active: true,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "100",
                winning: "250"
            },{
                ticket_number: "77",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "25",
                winning: ""
            },{
                ticket_number: "707",
                is_active: true,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "150",
                winning: "100"
            },{
                ticket_number: "587",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "350",
                winning: ""
            },{
                ticket_number: "128",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "25",
                winning: "75"
            },
        ];
        const megaJackpotData = [
            {
                ticket_number: "201",
                is_active: true,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "25",
                winning: "350"
            },{
                ticket_number: "88",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "25",
                winning: ""
            },{
                ticket_number: "707",
                is_active: true,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "150",
                winning: "100"
            },{
                ticket_number: "444",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "350",
                winning: ""
            },{
                ticket_number: "28",
                is_active: false,
                wallet_address: "0x76a9fdFaF38DB0f0E2Eb2C90Bd7FA759713f0248",
                price: "250",
                winning: "100"
            },
        ]
        setJackpotTickets(jackpotData);
        setMegaJackpotTickets(megaJackpotData);

        let filteredJackpotActiveData = jackpotData.filter((ticket:any) => ticket.is_active === true);
        let filteredJackpotInActiveData = jackpotData.filter((ticket:any) => ticket.is_active === false);
        setActiveTickets(filteredJackpotActiveData);
        setInActiveTickets(filteredJackpotInActiveData);

    },[])

    const handleClickJackpot = () => {
        let filteredJackpotActiveData = jackpotTickets.filter((ticket:any) => ticket.is_active === true);
        let filteredJackpotInActiveData = jackpotTickets.filter((ticket:any) => ticket.is_active === false);
        setActiveTickets(filteredJackpotActiveData);
        setInActiveTickets(filteredJackpotInActiveData);
        setIsActive(false)
    }

    const handleClickMegaJackpot = () => {
        let filteredMegaJackpotActiveData = megaJackpotTickets.filter((ticket:any) => ticket.is_active === true);
        let filteredMegaJackpotInActiveData = megaJackpotTickets.filter((ticket:any) => ticket.is_active === false);
        setActiveTickets(filteredMegaJackpotActiveData);
        setInActiveTickets(filteredMegaJackpotInActiveData);
        setIsActive(true)
    }

    return (
        <div className="profile">
            <div className="profile_header">
                {t('Мои билеты')}
            </div>
            <div className="profile_switch-btn-group">
                <div className={isActive ? "profile_switch-btn-group__btn": "profile_switch-btn-group__btn is-active-btn"} onClick={handleClickJackpot}>
                    <span className={isActive ? "profile_switch-btn-group__btn___content": "profile_switch-btn-group__btn___content is-active-btn" }>
                        {t('Джекпот')} ({jackpotTickets.length})
                    </span>
                </div>
                <div className={!isActive ? "profile_switch-btn-group__btn": "profile_switch-btn-group__btn is-active-btn"} onClick={handleClickMegaJackpot}>
                    <span className={!isActive ? "profile_switch-btn-group__btn___content": "profile_switch-btn-group__btn___content is-active-btn" }>
                        {t('Мега Джекпот')} ({megaJackpotTickets.length})
                    </span>
                </div>
            </div>

            <div className="active-tickets">
                <div className="active-tickets_header">
                    {t('Активные билеты')}
                </div>
                {activeTickets?.map((ticket:any) => {
                    return (
                        <Ticket
                            ticket={ticket}
                        />
                        )
                })}
                <div className="active-tickets_header">
                    {t('Предыдущие билеты')}
                </div>
                {inActiveTickets?.map((ticket:any) => {
                    return (
                        <Ticket
                            ticket={ticket}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Profile