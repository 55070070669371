import { ABI } from "./ABI/ABI"
import { tokenABI } from "./ABI/tokenABI"
import { mUSDTABI } from "./ABI/mUSDTABI"
import { userManager } from "./ABI/userManager"
import { ethers } from "ethers";
const Web3 = require("web3");

const web3 = new Web3(
  "https://data-seed-prebsc-2-s1.bnbchain.org:8545"
);
const TokenContractAddress = "0x97F93868515Bc8C63f31EC8e488f6099fDC01176";
const mUSDTAddress = "0x3051ee640D0A935BD181b0b81dde9d80998f4c15";
const contractAddress = "0xd5eA29F01F186975554424F2177fb106A2E581ae";
const userManagerContractAddress = "0xC318ed35841C6D556E3cF85867D162C82C3C975b"
const privateKey =
  "c36799f108a60ed678ea95830bcad4622d21d17dc06d86a6d0ff2e4253dc4dd2";
const senderAddress = web3.eth.accounts.privateKeyToAccount(privateKey).address;
const contractMLM = new web3.eth.Contract(ABI, contractAddress);
const contractUserManager = new web3.eth.Contract(userManager, userManagerContractAddress);

// export async function getUserInfo() {
//   try {
//     const web3 = new Web3(window.ethereum);
//     const contract = new web3.eth.Contract(ABI, contractAddress);
//     const accounts = await web3.eth.getAccounts();
//     // const accounts = await window.ethereum.request({
//     //   method: "eth_requestAccounts",
//     // });
//     const account = accounts[0];
//     let ballance = await getUserBallance();
//
//     const senderAddress =
//       web3.eth.accounts.privateKeyToAccount(privateKey).address;
//     var userInfo = await contract.methods
//       .getUserInfo(account)
//       .call({ from: senderAddress });
//     var ticketPrice = await contract.methods.ticketPrice().call();
//     ticketPrice = ticketPrice / 10 ** 18;
//
//     var cycleCount = await contract.methods
//       .cycleCount()
//       .call({ from: senderAddress });
//
//     // let referalIdForFront = generateString(12);
//     // Send a POST request with the data
//     fetch("https://cryptolottery777.com/public/data", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         data: userInfo,
//         address: account,
//         // referral_id_for_front: referalIdForFront,
//         cycleCount: cycleCount,
//         tickets_price: ticketPrice,
//         ballance_of: ballance,
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseData) => {})
//       .catch((error) => {
//         // Handle any errors
//         console.error("Ero:", error);
//       });
//     return userInfo;
//   } catch (error) {
//     console.error("Error creating mlm:", error);
//   }
// }
//return winnings tickets count and id in arr
export async function getWinningTickets() {
  let winningTicketsArr = [];
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ABI, contractAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    let winningTicketsLength = await contract.methods
      .winningTicketsCount()
      .call();

    for (let i = 0; i < winningTicketsLength; i++) {
      winningTicketsArr[i] = await contract.methods.winningTickets(i).call();
    }
    return winningTicketsArr;
  } catch (error) {
    console.error("Error creating NFT:", error);
  }
}

//return count of tickets and sold tickets
export async function getSoldTicketCount() {
  try {
    var numberOfTickets = await contractMLM.methods.numberOfTickets().call();
    var numberOfSoldTickets = await contractMLM.methods
      .numberOfSoldTickets()
      .call();
    var cycleCount = await contractMLM.methods
      .cycleCount()
      .call({ from: senderAddress });
    var ticketPrice = await contractMLM.methods.ticketPrice().call();
    ticketPrice = ticketPrice / 10 ** 18;
    let ticketsData = [numberOfSoldTickets, numberOfTickets];

    // // Send a POST request with the data
    // fetch("https://cryptolottery777.com/public/jackpot_sold_ticket_count", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ ticketsData, ticketPrice, cycleCount }),
    // })
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     // Process the response data
    //   })
    //   .catch((error) => {
    //     // Handle any errors
    //     console.error("Ero:", error);
    //   });
    // // window.location.reload();
    return ticketsData;
  } catch (error) {
    console.error("Error creating NFT:", error);
  }
}

//return current megajeckpot amount
export async function getMegaJackpotAmount() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ABI, contractAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    var megaJackpotAmount = await contract.methods
      .monthlyJackpotWinningAmount()
      .call();
    megaJackpotAmount = megaJackpotAmount / 10 ** 18;

    fetch("https://cryptolottery777.com/public/mega_jackpot_amount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(megaJackpotAmount),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Process the response data
      })
      .catch((error) => {
        // Handle any errors
        console.error("Erroro:", error);
      });
    window.location.reload();
    return megaJackpotAmount;
  } catch (error) {
    console.error("Error creating getMegaJackpotAmount:", error);
  }
}

// get megajeckpot start date
export async function getMegaJackpotStartTime() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ABI, contractAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    var megaJackpotStartTime = await contract.methods
      .monthlyJackpotStartTimestamp()
      .call();
    fetch("https://cryptolottery777.com/public/mega_jackpot_timestamp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(megaJackpotStartTime),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Process the response data
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
    window.location.reload();
    return megaJackpotStartTime;
  } catch (error) {
    console.error("Error creating getMegaJackpotStartTime:", error);
  }
}

// update DB for get notification
export async function setEmailForNotify() {
  let data = {
    wallet_address: "0xDb2c37a65C78f7Af52ac89E7d0c4123dFEEF7534",
    email: "avanesov@mail.ru",
  };
  fetch("https://cryptolottery777.com/public/email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((responseData) => {
      // Process the response data
    })
    .catch((error) => {
      // Handle any errors
      console.error("Ero:", error);
    });
  // return "vova_avanesov@mail.ru";00000000
}

export async function buyTicket(_account, ticketCount) {
  try {
    // Estimate gas for the transaction
    const estimatedGas = await contractMLM.methods
      .buyTickets(ticketCount)
      .estimateGas({ from: _account });

    // Get the current gas price
    const gasPrice = await web3.eth.getGasPrice();

    // Send the transaction with gas and gasPrice
    var buyTicket = await contractMLM.methods.buyTickets(ticketCount).send({
      from: _account,
      gas: estimatedGas, // Set the estimated gas limit
      gasPrice: gasPrice, // Set the current gas price
    });

    return buyTicket;
    // Send a POST request with the data
  } catch (error) {
    console.error("Error creating buyTicket:", error);
  }
}

export async function tokenApprove(amount) {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(tokenABI, TokenContractAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    // Estimate gas for the transaction
    const estimatedGas = await contract.methods
      .approve(contractAddress, web3.utils.toWei(amount, "ether"))
      .estimateGas({ from: account });

    // Get the current gas price
    const gasPrice = await web3.eth.getGasPrice();

    // Send the transaction with gas and gasPrice
    var approve = await contract.methods
      .approve(contractAddress, web3.utils.toWei(amount, "ether"))
      .send({
        from: account,
        gas: estimatedGas, // Set the estimated gas limit
        gasPrice: gasPrice, // Set the current gas price
      });

    return approve;
    // Send a POST request with the data
  } catch (error) {
    console.error("Error creating tokenApprove:", error);
  }
}

export async function rewardWinners() {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(ABI, contractAddress);

  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    const account = accounts[0];

    const nonce = await web3.eth.getTransactionCount(account);
    const gasLimit = 210000; // Set an initial gas limit

    // Estimate gas for the transaction
    const estimatedGas = await contract.methods
      .rewardWinners()
      .estimateGas({ from: account });

    const gasPrice = await web3.eth.getGasPrice();

    const txData = contract.methods.rewardWinners().encodeABI();

    const rawTx = {
      nonce: web3.utils.toHex(nonce),
      gasPrice: web3.utils.toHex(gasPrice),
      gasLimit: web3.utils.toHex(estimatedGas), // Use the estimated gas
      to: contractAddress,
      data: txData,
    };

    const signedTx = await web3.eth.accounts.signTransaction(rawTx, privateKey);

    const receipt = await web3.eth.sendSignedTransaction(
      signedTx.rawTransaction
    );
  } catch (error) {
    console.error("Error executing transaction:", error);
  }
}

export async function startNewCycle() {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(ABI, contractAddress);

  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    const account = accounts[0];

    const nonce = await web3.eth.getTransactionCount(account);
    const gasPrice = await web3.eth.getGasPrice();

    // Estimate gas for the transaction
    const estimatedGas = await contract.methods
      .startNewCycle()
      .estimateGas({ from: "0x98C46e0A66335A84067e1B99FfFf14808fC4c871" });

    const gasLimit = Math.max(estimatedGas, 210000); // Set a minimum gas limit

    const txData = contract.methods.startNewCycle().encodeABI();

    const rawTx = {
      gasPrice: web3.utils.toHex(gasPrice),
      gasLimit: web3.utils.toHex(gasLimit),
      to: contractAddress,
      data: txData,
    };

    const signedTx = await web3.eth.accounts.signTransaction(rawTx, privateKey);

    const receipt = await web3.eth.sendSignedTransaction(
      signedTx.rawTransaction
    );
  } catch (error) {
    console.error("Error executing transaction:", error);
  }
}

export async function mUSDTMint(amount) {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(mUSDTABI, mUSDTAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    // Calculate the amount in Wei
    const amountInWei = web3.utils.toWei((amount * 25).toString(), "ether");

    // Estimate gas for the transaction
    const estimatedGas = await contract.methods
      .mintTokens(amountInWei)
      .estimateGas({ from: account });

    // Get the current gas price
    const gasPrice = await web3.eth.getGasPrice();

    // Send the transaction with gas and gasPrice
    var mintTokens = await contract.methods.mintTokens(amountInWei).send({
      from: account,
      gas: estimatedGas, // Set the estimated gas limit
      gasPrice: gasPrice, // Set the current gas price
    });
    return mintTokens;
    // Send a POST request with the data
  } catch (error) {
    console.error("Error creating mUSDTMint:", error);
  }
}

// export async function addUserToMlm(account, referral_id) {
//   const web3 = new Web3(window.ethereum);
//   const contract = new web3.eth.Contract(ABI, contractAddress);
//   try {
//     const accounts = await window.ethereum.request({
//       method: "eth_requestAccounts",
//     });

//     const account = accounts[0];

//     const nonce = await web3.eth.getTransactionCount(account);
//     const gasPrice = await web3.eth.getGasPrice();
//     const gasLimit = 210000;

//     const txData = contract.methods
//       .addUserToMlm(account, referral_id)
//       .encodeABI();

//     const rawTx = {
//       gasPrice: gasPrice,
//       gasLimit: gasLimit,
//       to: contractAddress,
//       data: txData,
//     };

//     const signedTx = await web3.eth.accounts.signTransaction(rawTx, privateKey);

//     const receipt = await web3.eth.sendSignedTransaction(
//       signedTx.rawTransaction
//     );
//   } catch (error) {
//     console.error("Error executing transaction:", error);
//   }
// }

export async function getUserBallance() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(tokenABI, TokenContractAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    const senderAddress =
      web3.eth.accounts.privateKeyToAccount(privateKey).address;
    var balanceOf = await contract.methods.balanceOf(account).call();

    return balanceOf;
    // Send a POST request with the data
  } catch (error) {
    console.error("Error creating getUserBallance:", error);
  }
}

export async function getTicketPrice() {
  try {
    var ticketPrice = await contractMLM.methods.ticketPrice().call();

    return ticketPrice;
  } catch (error) {
    console.error("Error creating getTicketPrice:", error);
  }
}

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generateString(length) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getAvatar() {
  const requestOptions = {
    method: "GET",
  };
  fetch("https://cryptolottery777.com/public/avatar_t", requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      // Handle the Blob response here

      // Assuming you want to display the Blob as an image

      const imageURL = URL.createObjectURL(blob);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export async function getWinningAddress() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ABI, contractAddress);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    let winnerArr = [];
    let winningTicketsCount = await contract.methods
      .winningTicketsCount()
      .call();

    for (let i = 0; i < winningTicketsCount; i++) {
      let winnerObj = {};
      winnerObj.value = await contract.methods.winningTickets(i).call();
      winnerObj.key = await contract.methods
        .ticketNumberToAddress(winnerObj.value)
        .call();
      winnerArr.push(winnerObj);
    }

    fetch("https://cryptolottery777.com/public/winning_tickets_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(winnerArr),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Process the response data
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  } catch (error) {
    console.error("Error creating getWinningAddress:", error);
  }
}

export async function getUserInfoForTicketsTable() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ABI, contractAddress);
    const accounts = await web3.eth.getAccounts();
    // const accounts = await window.ethereum.request({
    //   method: "eth_requestAccounts",
    // });
    const account = accounts[0];

    const senderAddress =
      web3.eth.accounts.privateKeyToAccount(privateKey).address;
    var cycleCount = await contract.methods
      .cycleCount()
      .call({ from: senderAddress });

    var userInfo = await contract.methods
      .getUserInfo(account)
      .call({ from: senderAddress });
    return [userInfo, cycleCount];
  } catch (error) {
    console.error("Error creating getUserInfoForTicketsTable:", error);
  }
}

export async function getCycleCount() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ABI, contractAddress);

    const senderAddress =
      web3.eth.accounts.privateKeyToAccount(privateKey).address;
    var cycleCount = await contract.methods
      .cycleCount()
      .call({ from: senderAddress });
    return cycleCount;
  } catch (error) {
    console.error("Error creating getCycleCount:", error);
  }
}

// const quiknodeWebSocketUrl =
//   "wss://frosty-muddy-hexagon.bsc-testnet.quiknode.pro/4ab3b3df77fe4dadf45714324ed3d89cbd933211/";

// let provider = new Web3(
//   new Web3.providers.WebsocketProvider(quiknodeWebSocketUrl)
// );
// const testContract = new provider.eth.Contract(ABI, contractAddress);

// testContract.events.BoughtTicketsCountBonus().on("data", (event) => {
//   if (event) {
//     fetch("https://cryptolottery777.com/public/bonuses_data", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         wallet_address: event.returnValues.user,
//         bonusAmount: event.returnValues.bonusAmount,
//         event: event.event,
//       }),
//     }).catch((error) => {
//       // Handle any errors
//       console.error("Error:", error);
//     });
//   }
// });



/////// new web3 ////


export async function getTicketsStatus() {
  try {
    var tecketsStatus = await contractMLM.methods.getTicketsStatus().call();

    return tecketsStatus;
  } catch (error) {
    console.error("Error creating getTicketPrice:", error);
  }
}

export async function buyTickets(ticketId, ticketsCount, signer) {
  try {
    console.log("📡 Провайдер перед вызовом buyTickets:", signer);

    if (!signer) {
      console.error("❌ Ошибка: `signer` не передан");
      alert("Ошибка: `signer` отсутствует");
      return;
    }

    // ✅ Подключаем контракт
    const contract = new ethers.Contract(contractAddress, ABI, signer);

    console.log("🚀 Отправляем `buyTickets()`", { ticketId, ticketsCount });

    // ✅ Оцениваем газ
    const estimatedGas = await contract.buyTickets.estimateGas(ticketId, ticketsCount);

    // ✅ Отправляем транзакцию
    const tx = await contract.buyTickets(ticketId, ticketsCount, { gasLimit: estimatedGas });

    console.log("🔗 Транзакция отправлена, hash:", tx.hash);

    await tx.wait(); // Ждем подтверждения
    console.log("✅ Транзакция подтверждена!");

    return tx.hash;
  } catch (error) {
    alert(error)
    console.error("❌ Ошибка в `buyTickets()`:", error);
  }
}



export async function getUserInfo(_account) {
   try {
    var userInfo = await contractUserManager.methods.getUserInfo(_account,).call();

    return userInfo;
  } catch (error) {
    console.error("Error creating getTicketPrice:", error);
  }
}


export async function addressToHisReferrer(_acoount) {
  try {
    var referresAddres = await contractUserManager.methods.addressToHisReferrer(_acoount).call();
    return referresAddres;
  } catch (error) {
    console.error("Error get referrer:", error);
  }
}

export async function addUserToMlm(account, referrerId) {
  // ✅ Статичный RPC для BSC Testnet
  const BSC_TESTNET_RPC = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  const provider = new ethers.JsonRpcProvider(BSC_TESTNET_RPC);
  try {
      console.log("📡 Начало выполнения `addUserToMlm()`");

      if (!privateKey) {
          console.error("❌ Ошибка: `privateKey` не передан!");
          return;
      }

      // ✅ Проверяем входные данные
      if (!referrerId || isNaN(referrerId) || Number(referrerId) <= 0) {
          console.error("❌ Ошибка: referrerId некорректен:", referrerId);
          alert("Ошибка: referrerId должен быть положительным числом!");
          return;
      }

      if (!ethers.isAddress(account)) {
          console.error("❌ Ошибка: Некорректный Ethereum-адрес:", account);
          alert("Ошибка: Некорректный Ethereum-адрес!");
          return;
      }

      // ✅ Создаем кошелек через `privateKey`
      const wallet = new ethers.Wallet(privateKey, provider);
      console.log("🔑 Кошелек подключен, адрес:", wallet.address);

      // ✅ Подключаем контракт
      const contract = new ethers.Contract(userManagerContractAddress, userManager, wallet);

      // ✅ Приведение ID к `BigInt`
      const referrerIdFormatted = ethers.toBigInt(referrerId);

      console.log("🚀 Отправляем `addUserToMlm()`", { account, referrerIdFormatted });

      // ✅ Оцениваем газ
      const estimatedGas = await contract.addUserToMlm.estimateGas(account, referrerIdFormatted);

      // ✅ Отправляем транзакцию
      const tx = await contract.addUserToMlm(account, referrerIdFormatted, { 
          gasLimit: estimatedGas,
      });

      console.log("🔗 Транзакция отправлена, hash:", tx.hash);

      await tx.wait(); // Ждем подтверждения
      console.log("✅ Транзакция подтверждена!");

      return tx.hash;
  } catch (error) {
      console.error("❌ Ошибка в `addUserToMlm()`:", error);
  }
}

export async function approveUSDT(amount, signer) {
  try {
    console.log("📡 Провайдер перед вызовом approve:", signer);

    if (!signer) {
      console.error("❌ Ошибка: `signer` не передан");
      alert("Ошибка: `signer` отсутствует");
      return;
    }

    const contract = new ethers.Contract(mUSDTAddress, mUSDTABI, signer);
    const amountInWei = ethers.parseUnits(amount.toString(), 18); // Конвертация в Wei

    console.log("🚀 Отправляем `approve()`", { contractAddress, amountInWei });

    // ✅ Оцениваем газ
    const estimatedGas = await contract.approve.estimateGas(contractAddress, amountInWei);

    // ✅ Отправляем транзакцию
    const tx = await contract.approve(contractAddress, amountInWei, { gasLimit: estimatedGas });

    console.log("🔗 Транзакция отправлена, hash:", tx.hash);

    await tx.wait(); // Ждем подтверждения
    console.log("✅ Транзакция подтверждена!");

    return tx.hash;
  } catch (error) {
    console.error("❌ Ошибка в `approveUSDT()`:", error);
  }
}

