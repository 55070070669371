import React from "react";
import WebLogo from "../assets/images/web-logo.svg";
import QRCode from "../assets/images/QR-code.png";

function WebVersion() {
    return (
        <div className="web-version">
            <img src={WebLogo} alt="icon" width={364} height={222}/>
            <div className="web-version_content">
                <img src={QRCode} alt="" width={364}/>
                <div className="web-version_content__right">
                    <div className="web-version_content__right___title">
                        Scan QR code to access mobile app
                    </div>
                    <div className="web-version_content__right___info">
                        1. Open camera on your phone
                    </div>
                    <div className="web-version_content__right___info">
                        2. Point your phone at this screen
                    </div>
                    <div className="web-version_content__right___info">
                        3. Scan QR code
                    </div>
                    <div className="web-version_content__right___info">
                        4. Follow the link
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WebVersion