import GameIcon from "../assets/icons/game-icon.svg";
import GameDefault from "../assets/icons/game-default-icon.svg";
import FriendsIcon from "../assets/icons/friends-icon.svg";
import FriendsDefault from "../assets/icons/friends-default-icon.svg";
import HowPlayIcon from "../assets/icons/how-play-icon.svg";
import HowPlayDefault from "../assets/icons/how-play-default-icon.svg";
import MoreIcon from "../assets/icons/more-icon.svg";
import MoreDefault from "../assets/icons/more-deafult-icon.svg";
import ProfileIcon from "../assets/icons/profile-icon.svg";
import ProfileDefault from "../assets/icons/profile-default-icon.svg";
import FriendsGroupIcon from "../assets/icons/friends-group-icon.svg";
import InviteIcon from "../assets/icons/invite-icon.svg";
import EarningsIcon from "../assets/icons/earnings-icon.svg";
import WatsAppIcon from "../assets/icons/watsApp-icon.svg";
import FacebookIcon from "../assets/icons/facebook-icon.svg";
import XIcon from "../assets/icons/x-icon.svg";
import GmailIcon from "../assets/icons/gmail-icon.svg";
import TelegramIcon from "../assets/icons/telegram-icon.svg";
import InstagramIcon from "../assets/icons/instagram-icon.svg";
import YouTubeIcon from "../assets/icons/youtube-icon.svg";
import DiscordIcon from "../assets/icons/discord-icon.svg";
import Wallet from "../assets/icons/video-wallet.svg";
import FiatCreditCard from "../assets/icons/fiat-credit-card.svg";
import FiatCash from "../assets/icons/fiat-cash.svg";
import CommunitySpeaker from "../assets/icons/community-speaker.svg";
import SmartContractSignature from "../assets/icons/smart-contract_signature.svg";
import ChartBar from "../assets/icons/chart-bar.svg";
import Web3 from "../assets/icons/web3.svg";
import MoneyBag from "../assets/icons/money-bag.svg";
import LiquidityPool from "../assets/icons/liquidity-pool.svg";
import RulesIcon from "../assets/icons/rules-icon.svg";
import FaqIcon from "../assets/icons/faq-icon.svg";
import UserTermsIcon from "../assets/icons/user-terms-icon.svg";
import PrivacyPolicyIcon from "../assets/icons/privacy-policy-icon.svg";
import CooperationIcon from "../assets/icons/cooperation-icon.svg";
import SocialGroupIcon from "../assets/icons/social-group-icon.svg";

export const AllTicketsCount = 777;

export const footerItems = [
    {
        name: "Игры",
        selectedIcon: GameIcon,
        defaultIcon: GameDefault
    },
    {
        name: "Друзья",
        selectedIcon: FriendsIcon,
        defaultIcon: FriendsDefault
    },
    {
        name: "Как играть",
        selectedIcon: HowPlayIcon,
        defaultIcon: HowPlayDefault
    },
    {
        name: "Профиль",
        selectedIcon: ProfileIcon,
        defaultIcon: ProfileDefault
    },
    {
        name: "Ещё",
        selectedIcon: MoreIcon,
        defaultIcon: MoreDefault
    },
]

export const referralProgramInfo= [
    {
        icon: FriendsGroupIcon,
        name: "Мои друзья"
    },
    {
        icon: InviteIcon,
        name: "Приглашенных друзей"
    },
    {
        icon: EarningsIcon,
        name: "Заработок от друзей"
    },
]

export const sharingIcons = [
    {
        icon: WatsAppIcon,
        name: "WhatsApp"
    },
    {
        icon: FacebookIcon,
        name: "Facebook"
    },
    {
        icon: XIcon,
        name: "X"
    },
    {
        icon: GmailIcon,
        name: "Gmail"
    },
    {
        icon: TelegramIcon,
        name: "Telegram"
    },
    {
        icon: InstagramIcon,
        name: "Instagram"
    },
    {
        icon: YouTubeIcon,
        name: "YouTube"
    },
    {
        icon: DiscordIcon,
        name: "Discord"
    },
];

export const socialMediaData = [
    {
        icon: WatsAppIcon,
        name: "WhatsApp"
    },
    {
        icon: FacebookIcon,
        name: "Facebook"
    },
    {
        icon: XIcon,
        name: "X"
    },
    {
        icon: TelegramIcon,
        name: "Telegram"
    },
    {
        icon: InstagramIcon,
        name: "Instagram"
    },
    {
        icon: YouTubeIcon,
        name: "YouTube"
    },
    {
        icon: DiscordIcon,
        name: "Discord"
    },
]

export const VideoData = [
    {
        src: "https://www.w3schools.com/html/mov_bbb.mp4",
        image: Wallet,
        text: "Как подключиться?"
    },
    {
        src: "https://videos.pexels.com/video-files/28339273/12362435_640_360_24fps.mp4",
        image: FiatCreditCard,
        text: "Как пополнить счет?"
    },
    {
        src: "https://videos.pexels.com/video-files/27790932/12225582_2560_1440_30fps.mp4",
        image: FiatCash,
        text: "Как купить билет?"
    },
    {
        src: "https://videos.pexels.com/video-files/28180180/12319987_640_360_60fps.mp4",
        image: CommunitySpeaker,
        text: "Как играть в игры?"
    },
    {
        src: "https://videos.pexels.com/video-files/28371212/12369246_640_360_30fps.mp4",
        image: SmartContractSignature,
        text: "Как проверить билеты?"
    },
    {
        src: "https://videos.pexels.com/video-files/28295445/12353429_640_360_30fps.mp4",
        image: ChartBar,
        text: "Как проверить результат?"
    },
    {
        src: "https://videos.pexels.com/video-files/27982624/12281077_640_360_30fps.mp4",
        image: Web3,
        text: "Как смотреть розыгрыши?"
    },
    {
        src: "https://videos.pexels.com/video-files/13299023/13299023-sd_640_360_30fps.mp4",
        image: MoneyBag,
        text: "Как вывести выигрыш?"
    },
    {
        src: "https://videos.pexels.com/video-files/19090734/19090734-sd_640_360_25fps.mp4",
        image: LiquidityPool,
        text: "Как подарить билеты?"
    },
];

export const moreData = [
    {
        icon: RulesIcon,
        name: "Правила"
    },
    {
        icon: FaqIcon,
        name: "ЧАВО"
    },
    {
        icon: UserTermsIcon,
        name: "Условия пользователя"
    },
    {
        icon: PrivacyPolicyIcon,
        name: "Политика конфиденциальности"
    },
    {
        icon: CooperationIcon,
        name: "Сотрудничества"
    },
    {
        icon: SocialGroupIcon,
        name: "Мы в социальных сетях"
    },

];

export const RuleItems = [
    {
        title: "Участие в лотерее",
        content_1: "Для участия необходимо зарегистрироваться через официальный смарт-контракт лотереи.",
        content_2: "Покупка билета осуществляется посредством перевода установленной суммы в криптовалюте на адрес смарт-контракта.",
        content_3: "Каждому участнику выдается уникальный идентификатор билета, который записывается в блокчейн.",
    },
    {
        title: "Стоимость билета",
        content_1: "Цена одного билета составляет фиксированную сумму, указанную в условиях лотереи.",
        content_2: "Покупка билетов возможна только в поддерживаемых криптовалютах.",
        content_3: "В случае изменения курса криптовалют, стоимость билета остается неизменной.",
    },
    {
        title: "Период проведения",
        content_1: "Продажа билетов начинается с момента активации смарт-контракта.",
        content_2: "Окончание продажи билетов и дата розыгрыша устанавливаются в условиях лотереи.",
        content_3: "Розыгрыш проводится автоматически после завершения продажи билетов.",
    },
    {
        title: "Прозрачность и честность",
        content_1: "Смарт-контракт лотереи полностью прозрачен и доступен для проверки в открытом блокчейне.",
        content_2: "Розыгрыш победителей осуществляется с использованием алгоритмов генерации случайных чисел, интегрированных в смарт-контракт.",
        content_3: "Все данные о билетах, участниках и результатах фиксируются в блокчейне и не подлежат изменению.",
    },
    {
        title: "Призовой фонд",
        content_1: "Призовой фонд формируется из средств, собранных от продажи билетов.",
        content_2: "Размер выигрышей и количество призовых мест указаны в условиях лотереи.",
        content_3: "Комиссия организаторов  автоматически вычитается из призового фонда и отображается в условиях лотереи.",
    },
    {
        title: "Определение победителей",
        content_1: "Победители определяются на основе алгоритма случайной выборки среди уникальных идентификаторов билетов.",
        content_2: "Результаты розыгрыша фиксируются в блокчейне и доступны для проверки.",
        content_3: "В случае отсутствия победителя по каким-либо техническим причинам, средства участников возвращаются автоматически.",
    },
    {
        title: "Выплата выигрышей",
        content_1: "Выигрыши автоматически перечисляются на адреса криптовалютных кошельков, с которых были куплены билеты.",
        content_2: "Участники несут ответственность за доступ к своему кошельку. В случае утери доступа организаторы не несут ответственности за невыданный выигрыш.",
    },
    {
        title: "Ограничения и ответственность",
        content_1: "Участие запрещено лицам, проживающим в юрисдикциях, где участие в лотереях запрещено законом.",
        content_2: "Организаторы не несут ответственности за технические сбои в сети блокчейна, влияющие на процесс лотереи.",
        content_3: "Участие в лотерее подразумевает согласие с указанными правилами.",
    },
    {
        title: "Прочие условия",
        content_1: "Организаторы оставляют за собой право изменять условия лотереи только до начала продажи билетов.",
        content_2: "Все изменения фиксируются в обновленной версии смарт-контракта, доступной для проверки.",
    },

];

export const FaqItems = [
    {
        title: "Как приобрести билет?",
        content: 'Откройте наш сайт и нажмите кнопку "Подключить кошелек".Поддерживаются популярные кошельки, такие как MetaMask, Trust Wallet .Выберите нужный уровень и количество билетов.Убедитесь, что у вас на кошельке достаточно средств в поддерживаемой криптовалюте (например USDT).Переведите указанную сумму на адрес смарт-контракта.После успешной транзакции вы получите уникальный идентификатор билета, записанный в блокчейн.'
    },
    {
        title: "Какая стоимость билета?",
        content: "Стоимость одного билета фиксирована и указана на странице текущего розыгрыша. Она оплачивается в поддерживаемой криптовалюте USDT, например: для WEB3 1 билет - 25 USDT Для ТГ Стартовый уровень «Майнинг Удачи»  1 билет - 1 USDT Стандарт уровень «Золотой Шанс»  1 билет - 5 USDT Премиум уровень «Крипто Сокровище»   1 билет - 10 USDT. Или другая актуальная цена билета, установленный для конкретного раунда. Убедитесь, что на вашем кошельке достаточно средств, чтобы оплатить билет и покрыть возможные транзакционные комиссии."
    },
    {
        title: "Сколько я могу приобрести билетов?",
        content: "Количество билетов, которые вы можете приобрести, не ограничено. Вы можете покупать столько билетов, сколько пожелаете, что увеличивает ваши шансы на выигрыш.Однако убедитесь, что вы соблюдаете условия и правила участия, указанные для конкретного розыгрыша."
    },
    {
        title: "Как часто происходит розыгрыш?",
        content: "Как только наши участники приобретут 777 билетов, мы немедленно объявим победителей."
    },
    {
        title: "Как распределяются выигрыш?",
        content: "Джекпот:  - 100* Х (х-стоимость билета), 125 выигрышей - 1*Х (х-стоимость билета), 62 выигрыша - 2*Х (х-стоимость билета), 16 выигрышей - 4*Х (х-стоимость билета)"
    },
    {
        title: "Как выиграть Джекпот?",
        content: "Для выигрыша Джекпота, участнику необходимо купить билет и его номер должен совпасть с определенным выигрышными номером, который выбирается случайным образом при каждом розыгрыше. Частота Розыгрышей Джекпота: Джекпот разыгрывается после продажи каждых 777 билетов."
    },
    {
        title: "Как выиграть Мегаджекпот?",
        content: "С каждого розыгрыша 3% от общей суммы взносов отправляются на специальный счет Мега Джекпота. Растущий Выигрыш: В течение 30 дней мы аккумулируем средства на счете Мега Джекпота. По истечении 30 дней наступает самое ожидаемое мгновение - Грандиозный Розыгрыш Мега Джекпота!"
    },
    {
        title: "Какое реферальное вознаграждение?",
        content: "Каждый участник, который привлекает нового игрока, получает 5% от суммы покупки одного билета  за каждый привлеченный билет. Активный приглашающий участник - это тот, кто продал не менее 5 билетов,получает 10 %  от суммы покупки. Реферальные уровни: На первом уровне каждый участник получает 10% от выигрыша каждого приведенного другом участника. На втором уровне каждый участник получает 5% от выигрыша каждого участника, приглашенного его прямым рефералом.На третьем уровне каждый участник получает 2% от выигрыша каждого участника, приглашенного его прямым или косвенным рефералом на втором уровне."
    },
    {
        title: "Какие бонусы можно получить?",
        content: "Бонус за привлечение рефералов: Игрок получит дополнительный бонус в размере 5 USDT, за привлечение 10 рефералов. Бонус за продажу билетов: Игрок получит дополнительный бонус в размере 100 USDT, за продажу 50 билетов. Бонус за победу в лотерее: Игрок получит дополнительный бонус в размере 50 USDT, при выигрыше в 3 розыгрышах подряд. Бонус за участие в лотерее: Игрок может получить дополнительный бонус в размере 5 USDT ,если купит 10 билетов."
    },
];

export const userTermsInfo = [
    {
        title: "Личная безопасность в цифровом мире",
        content: ""
    },
    {
        title: "Безопасность персональных данных",
        content: "Никогда не делитесь личной информацией (номер телефона, адрес, данные банковских карт) с незнакомыми людьми или подозрительными сайтами. Используйте сложные пароли для всех аккаунтов и изменяйте их регулярно. Включите двухфакторную аутентификацию (2FA) на всех платформах, где это возможно."
    },
    {
        title: "Осторожность в социальных сетях",
        content: "Не публикуйте информацию, которая может раскрыть ваше местоположение или другие личные данные. Настройте параметры конфиденциальности, чтобы ограничить доступ к вашим публикациям. Будьте внимательны к фишинговым ссылкам, которые могут приходить через сообщения."
    },
    {
        title: "Защита устройств",
        content: "Используйте антивирусное программное обеспечение и регулярно обновляйте его.Не устанавливайте приложения из недоверенных источников.Защитите устройства паролем, PIN-кодом или биометрией."
    },
    {
        title: "Безопасность в сети",
        content: 'Избегайте использования общедоступных Wi-Fi сетей без VPN.Убедитесь, что сайты, на которых вы вводите личные данные, используют защищенное соединение (адрес сайта должен начинаться с "https://").Регулярно проверяйте настройки конфиденциальности в своих аккаунтах.'
    },
    {
        title: "Финансовая безопасность",
        content: 'Проверяйте транзакции и следите за выписками по банковским счетам. Никогда не переводите деньги незнакомым людям или в ответ на подозрительные сообщения. Будьте внимательны к схемам мошенничества, особенно связанным с "легким заработком".'
    },
    {
        title: "Личная безопасность оффлайн",
        content: "Не делитесь своими планами с посторонними людьми. Сообщайте близким о своем местонахождении, особенно в случае встреч с незнакомцами. В ситуациях, когда вы чувствуете угрозу, немедленно обращайтесь в правоохранительные органы."
    },
    {
        title: "Психологическая безопасность",
        content: "Не отвечайте на оскорбительные или угрожающие сообщения. Используйте функции блокировки и жалоб на платформе, если сталкиваетесь с агрессией. Если вы чувствуете себя подавленным или запуганным, обратитесь за поддержкой к друзьям, близким или специалистам."
    },
    {
        title: "Обучение и осведомленность",
        content: "Регулярно обновляйте свои знания о современных угрозах и способах защиты. Делитесь этими знаниями с близкими, особенно с детьми и пожилыми людьми. Участвуйте в обучающих мероприятиях или вебинарах, посвященных кибербезопасности и личной защите."
    },
    {
        title: "",
        content: "Вы несете полную ответственность за сохранность Ваших кошельков и отвечаете за все транзакции по Вашим кошелькам.  Cryptolottorey7777 ни при каких обстоятельствах  не несет ответственности за любые потери или последствия, вызванные санкционированным или несанкционированным использованием средств или учетных данных Ваших кошельков, включая, помимо прочего, потерю личных средств, разглашение информации, выдачу информации, согласие или подчинение различным правилам и соглашениям путем клика на сайте.Мы не несем никакой ответственности или обязательств за ошибки, вирусы, троянские кони или подобные программы, которые могут быть переданы на веб-сайт или через него."
    }
]
