import React from "react";
import CertikLogo from "../assets/images/certik-logo.svg";
import {moreData} from "../constants/const";
import InfoItem from "../components/InfoItem";
import {useNavigate} from "react-router";

function More() {
    const navigate = useNavigate()

    const handleNavigate = (item: number) => {
        switch (item) {
            case 0:
                navigate("/rules")
                break;
            case 1:
                navigate("/faq")
                break;
            case 2:
                navigate("/user-terms")
                break;
            case 3:
                navigate("/privacy-policy")
                break;
            case 4:
                navigate("/cooperation")
                break;
            case 5:
                navigate("/social-media")
                break;
            default:
                navigate("*")
        }
    };

    return (
        <div className="more">
            {moreData.map((item: any, index) => {
                return (
                    <InfoItem
                        key={index}
                        index={index}
                        item={item}
                        func={() => handleNavigate(index)}
                        isLine
                    />
                )
            })}
            <div className="more_logo">
                <img src={CertikLogo} alt="icon" width={122} height={29}/>
            </div>
        </div>
    )
}

export default More