import React, { useEffect, useState } from "react";
import JackpotImage from "../assets/images/2500-usdt.svg";
import MegaJackpotImage from "../assets/images/mega-jackpot-image.svg";
import { useTranslation } from "react-i18next";
import MainButton from "../components/MainButton";
import ButTicketIcon from "../assets/icons/buy-ticket-icon.svg";
import Countdown from "react-countdown";
import Completionist from "../components/Completionist";
import { useNavigate } from "react-router";
import { getUserInfo, getSoldTicketCount, addressToHisReferrer, addUserToMlm } from "../web3";




function AuthLayouth({ address, chainId, isConnected, status, open, provider }: any) {
    const [timeStamp, setTimeStamp] = useState<any>();
    const [allTicketsCount, setAllTicketsCount] = useState([]);
    const [userInfo, setUserInfo] = useState([])
    const { t } = useTranslation();
    const navigate = useNavigate()

    useEffect(() => {
        getSoldTicketCount().then((res: any) => {
            setAllTicketsCount(res)
        });
        setTimeStamp(1785412422);
    }, []);

    useEffect(() => {
        if (address) {
            getUserInfo(address).then(res => {
                console.log("user-Info", res)
            })
        }
    }, [address])

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a complete state
            return (
                <Completionist
                    days={0}
                    hours={0}
                    minutes={0}
                    seconds={0}
                />
            )
        } else {
            // Render a countdown
            return (
                <Completionist
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                />
            );
        }
    };


    const handleBuyJackpotTicket = async () => {
        if (isConnected) {
            if (localStorage.getItem('ref')) {
                var referrer_address: any = localStorage.getItem('ref');
                if (referrer_address?.endsWith('/')) {
                    referrer_address = referrer_address.slice(0, -1);
                }
            } else {
                referrer_address = 0

            }          
            try {
                await addressToHisReferrer(address).then((res) => {
                    console.log(referrer_address);
                    
                    if (res.toLowerCase() === "0x0000000000000000000000000000000000000000") {
                        console.log("SSSS",referrer_address);  
                        getUserInfo(referrer_address).then((res) => {
                            let referrerID = Number(res?.addressId);
                            console.log("referrerId", referrerID);
                            console.log("address", address);
                            console.log("ressssss", res);
                            addUserToMlm(address, referrerID).then((res)=>{
                                navigate('/jackpot');        
                            });
                            // console.log("MLM response:", mlmResponse);
                            
                        });

                    } else {
                        navigate('/jackpot');
                    }

                })


                // navigate('/jackpot');
            } catch (error) {
                console.error("Error in handleBuyJackpotTicket:", error);
            }
        } else {
            open();
        }
    };


    return (
        <div className="auth-layout">
            <div className="jackpot-card">
                <div className="jackpot-card_header">
                    <div className="jackpot-card_header__info">
                        <span className="jackpot-card_header__info___title">
                            {t('Джекпот')}
                        </span>
                        <span className="jackpot-card_header__info___subtitle">
                            {t('Доступные билеты')}:
                        </span>
                        <div className="jackpot-card_header__info___ticket-count">
                            <span className="jackpot-card_header__info___ticket-count____accessible-count">
                                {Array.isArray(allTicketsCount) && allTicketsCount.length > 0 ? allTicketsCount[1] - allTicketsCount[0] : '0'}
                            </span>
                            <span className="jackpot-card_header__info___ticket-count____total-count">
                                / {Array.isArray(allTicketsCount) && allTicketsCount.length > 1 ? allTicketsCount[1] : '0'}
                            </span>
                        </div>
                    </div>
                    <img src={JackpotImage} alt="icon" width={160} height={168} />
                </div>

                <div className="jackpot-card_content">
                    {t('Приобретайте билеты нашей лотереи по доступной цене в 25 USDT за билет. Чем больше билетов у вас есть, тем больше шансов на выигрыш вы имеете!')}
                </div>
                <div className="jackpot-card_btn">
                    <MainButton
                        icon={ButTicketIcon}
                        text={'Купить билет'}
                        func={handleBuyJackpotTicket}
                    />
                </div>
            </div>
            <div className="mega-jackpot-card">
                <div className="mega-jackpot-card_header">
                    <div className="mega-jackpot-card_header__title">
                        {t('Мега Джекпот')}
                    </div>
                    <img src={MegaJackpotImage} alt="icon" width={163} height={107} />
                    {timeStamp && (
                        <div className="datetime-container">
                            <Countdown date={Date.now() + timeStamp} renderer={renderer} />
                        </div>
                    )}
                </div>
                <div className="jackpot-card_content">
                    {t('У вас есть шанс стать обладателем огромного приза, который изменит вашу жизнь навсегда. Время идет!')}
                </div>
                <div className="jackpot-card_btn">
                </div>
            </div>
        </div>
    )
}

export default AuthLayouth;