import React, {useEffect, useState} from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { allRoutes } from "./allRoutes";
import {pathToFileURL} from "url";

function IndexRoute({ address, chainId, isConnected, status, open,provider }: any) {
    const [referralId, setReferralId] = useState("")
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let pathName = location.pathname

        if (pathName.length >= 30) {
            // navigate('/');
            let newPathName = pathName.slice(1);
            localStorage.setItem("ref",newPathName)
            setReferralId(newPathName)
        }else {
            let x:any = localStorage.getItem("ref");
            if (x && x.length >= 30 && pathName !== x){
                setReferralId(x)
            }else {
                setReferralId("newID")
            }
        }
    }, []);

    console.log("referralId=>>",referralId)

    return (
        <>
            <Routes>
                {allRoutes.map((route, index) => (
                    <Route
                        path={route.path}
                        element={
                            <route.component
                                address={address}
                                chainId={chainId}
                                isConnected={isConnected}
                                open={open}
                                status={status}
                                referralId={referralId}
                                provider={provider}
                            />
                        }
                        key={index}
                    />
                ))}
            </Routes>
        </>
    );
}

export default IndexRoute;
