import React, { useEffect, useState } from "react";
import FooterItem from "../components/FooterItem";
import { footerItems } from "../constants/const";
import { useNavigate } from "react-router";
import { addressToHisReferrer, addUserToMlm, getUserInfo } from "../web3";

function Footer({ address, isConnected, open }: any) {
    const [activeIconName, setActiveIconName] = useState(localStorage.getItem("route"));
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.setItem("route", "Игры")
    }, [])

    const handleNavigate = (item: string) => {
        localStorage.setItem("route", item)
        console.log("ITEMMMM", item)
        switch (item) {
            case "Игры":
                navigate("/")
                break;
            case "Друзья":
                if (isConnected) {
                    let referrer_address: any = localStorage.getItem('ref');
                    if (referrer_address?.endsWith('/')) {
                        referrer_address = referrer_address.slice(0, -1);
                    }
                    try {
                        addressToHisReferrer(address).then((res) => {
                            console.log("addToHisRef:", res);
                            if (res.toLowerCase() === "0x0000000000000000000000000000000000000000") {
                                console.log("Calling addUserToMlm with:", referrer_address, address);
                                getUserInfo(referrer_address).then((res) => {
                                    let referrerID = Number(res?.addressId);
                                    console.log("rezz", referrerID);
                                    let mlmResponse = addUserToMlm(address, referrerID);
                                    console.log("MLM response:", mlmResponse);
                                    navigate('/jackpot');
                                })
                            }    
                        })
                        

                    } catch (error) {
                        console.error("Error in handleBuyJackpotTicket:", error);
                    }

                    navigate("/friends")
                } else open()
                break;
            case "Как играть":
                navigate("/how-to-play")
                break;
            case "Профиль":
                if (isConnected) {
                    navigate("/profile")
                } else open()
                break;
            case "Ещё":
                navigate("/more")
                break;
            default:
                navigate("*")

        }
        setActiveIconName(item)
    };

    return (
        <div className="footer">
            {footerItems.map((footerItem, index) => {
                return (
                    <FooterItem
                        key={index}
                        name={footerItem.name}
                        selectedIcon={footerItem.selectedIcon}
                        defaultIcon={footerItem.defaultIcon}
                        func={() => handleNavigate(footerItem.name)}
                        activeIconName={activeIconName}
                    />
                )
            })}
        </div>
    )
}

export default Footer