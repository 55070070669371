import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RocketImage from "../assets/images/rocket.svg";
import FriendsGroupIcon from "../assets/icons/friends-group-icon.svg";
import EarningsIcon from "../assets/icons/earnings-icon.svg";
import MainButton from "../components/MainButton";
import { sharingIcons } from "../constants/const";
import { Modal } from "antd";
import { addressToHisReferrer, getUserInfo } from "../web3";

function Friends(data: any) {
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [shareReferral, setShareReferral] = useState("");
    const [myOwner, setMyOwner] = useState("");
    const [userData, setUserData] = useState<any>([])
    const [referrerAddress, setReferrerAddress] = useState<any>()
    const [totalRewards, setTotalRewards] = useState<any>(0)
    const { t } = useTranslation();

    useEffect(() => {
        let refCode = data?.address;
        setShareReferral(refCode);
        getUserInfo(data?.address).then((res) => {
            setUserData(res)
            // setTotalRewards(res?.totalRewards)
        })
        addressToHisReferrer(refCode).then((res: any) => {
            setReferrerAddress(res)
        });
        setMyOwner(data?.referralId)
    }, [data?.address])

    const handleOpenShareModal = () => {
        setShareModalOpen(true)
    };

    const handleShareReferral = (socialName: any) => {
        const shareMessage = `Привет! Мой реферальный код: ${shareReferral}. Присоединяйтесь ко мне!`;

        switch (socialName) {
            case 'WhatsApp':
                const whatsappWebLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage + ' https://cryptolottery777.com/#/' + shareReferral)}`;
                window.location.href = whatsappWebLink;
                break;
            case 'Facebook':
                const shareUrl = `https://cryptolottery777.com/#/${shareReferral}`;
                const facebookWebLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
                window.open(facebookWebLink, "_blank");
                break;
            case 'X':
                const shareXUrl = `https://cryptolottery777.com/#/${shareReferral}`;
                const xWebLink = `https://x.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&url=${encodeURIComponent(shareXUrl)}`;
                window.open(xWebLink, "_blank");
                break;
            case 'Gmail':
                const subject = "Присоединяйтесь ко мне!";
                const body = `Привет! Мой реферальный код: ${shareReferral}. Присоединяйтесь ко мне! Вот ссылка: https://cryptolottery777.com/#/${shareReferral}`;
                const gmailWebLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                window.location.href = gmailWebLink;
                break;
            case 'Telegram':
                const webLink = `https://t.me/share/url?url=https://cryptolottery777.com/#/${shareReferral}/&text=${encodeURIComponent(shareMessage)}`;
                window.location.href = webLink;
                break;
            case 'Instagram':
                window.open("https://www.instagram.com/", "_blank");
                break;
            case 'YouTube':
                window.open("https://www.youtube.com/", "_blank");
                break;
            case 'Discord':
                window.open("https://discord.com/", "_blank");
                break;
            default:
                console.log(`Sorry, we are out of ${socialName}.`);
        }
    }

    return (
        <div className="friends">
            <div className="friends_header">
                <div className="friends_header__top">
                    <div className="friends_header__top___title">
                        {t('Реферальная программа')}
                    </div>
                    <img src={RocketImage} alt="icon" className="friends_header__top___logo" />
                </div>
                <div className="friends_header__content">
                    {t('Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки. Кроме того, если вы продали не менее 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.')}
                </div>
            </div>
            <div className="friends_identifier">
                <div className="friends_identifier__title">
                    {t('Ваш идентификатор')}
                </div>
                <div className="friends_identifier__content">
                    <span>{shareReferral?.slice(0, 5) + "..." + shareReferral?.slice(-5)}</span>
                </div>
            </div>
            <MainButton
                text={'Поделиться моим кодом'}
                func={handleOpenShareModal}
            />
            <div className="friends_friends-group">
                <div className="friends_friends-group__title">
                    {t('Друзья')}
                </div>
                <div className="friends_friends-group__item">
                    <div className="friends_friends-group__item___info">
                        <img src={FriendsGroupIcon} alt="" width={32} height={32} />
                        <span>{t('Мои друзья')}</span>
                    </div>
                    <div className="friends_friends-group__item___count">
                        {userData?.referralsCount}
                    </div>
                </div>
                <div className="friends_friends-group__item">
                    <div className="friends_friends-group__item___info">
                        <img src={EarningsIcon} alt="" width={32} height={32} />
                        <span>{t('Заработок от друзей')}</span>
                    </div>
                    <div className="friends_friends-group__item___count">
                        {userData?.totalRewards / 10 ** 18}$
                    </div>
                </div>
            </div>
            <div className="friends_identifier">
                <div className="friends_identifier__title">
                    {t('Ваш пригласитель')}
                </div>
                <div className="friends_identifier__content">
                    <span>{referrerAddress?.slice(0, 5) + "..." + referrerAddress?.slice(-5)}</span>
                </div>
            </div>

            <Modal
                open={shareModalOpen}
                footer={null}
                onCancel={() => setShareModalOpen(false)}
                className="share-modal"
                title={t("Поделиться")}
            >
                <div className="share-modal_content">
                    {t('Поделитесь своим реферальным идентификатором в социальных сетях')}
                </div>
                <div className="share-modal_icons-group">
                    {sharingIcons.map((item: any, index) => {
                        return (
                            <div className="share-modal_icons-group__item" key={index}
                                onClick={() => handleShareReferral(item.name)}>
                                <img src={item.icon} alt="icon" width={48} height={48} />
                                <span>{item.name}</span>
                            </div>
                        )
                    })}
                </div>
            </Modal>
        </div>
    )
}

export default Friends