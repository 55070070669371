import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from "./auth/Header";
import Footer from "./auth/Footer";
import IndexRoute from "./routes/IndexRoute";
// import {ApiClient} from "./utils/ApiClient";
import WebVersion from "./pages/WebVersion";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";
import {createWeb3Modal, defaultConfig} from '@web3modal/ethers/react';
import {useWeb3Modal, useWeb3ModalProvider} from '@web3modal/ethers/react';
import {ethers} from "ethers";

// 1. Get projectId
const projectId = '74370368811ba66914930f4fb4b97b9d'

// 2. Set chains
const mainnet = {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    currency: 'tBNB',
    explorerUrl: 'https://bscscan.io',
    rpcUrl: 'https://data-seed-prebsc-1-s2.bnbchain.org:8545'
}

// 3. Create a metadata object
const metadata = {
    name: 'CryptoLottery777',
    description: 'CryptoLottery777',
    url: 'https://cryptolottery777.com/', // origin must match your domain & subdomain
    icons: ["avatar"]
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 97 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeMode: "dark",
    themeVariables: {
        "--w3m-font-family": 'Noto Sans Regular',
        "--w3m-accent": "#FFF",
        "--w3m-border-radius-master": "16px",
    },
})

function App() {
    const [provider, setProvider] = useState()
    const { address, chainId, isConnected, status } = useWeb3ModalAccount();
    const { open } = useWeb3Modal();
    const { walletProvider } = useWeb3ModalProvider(); // Extract walletProvider safely

    console.log("STATUS:", status);

    useEffect(() => {
        if (isConnected && walletProvider) {
            getSigner()
        }
    }, [isConnected, walletProvider]); // Re-run when provider is updated

    const getSigner = async () => {
        if (!isConnected) {
            open();
            console.error("Wallet not connected. Please connect your wallet.");
            return;
        }

        if (!walletProvider) {
            console.error("Provider is null. Try reconnecting.");
            return;
        }

        try {
            // Convert the provider into an Ethers.js provider
            const ethersProvider = new ethers.BrowserProvider(walletProvider);

            // Get the signer
            const signer:any = await ethersProvider.getSigner();
            console.log("Signer:", signer);
            setProvider(signer)
        } catch (error) {
            console.error("Error getting signer:", error);
        }
    };



    return (
        <div className="App">
            <div className="mob-version">
                <Header
                    address={address}
                    chainId={chainId}
                    isConnected={isConnected}
                    open={open}
                    status={status}
                />
                <IndexRoute
                    address={address}
                    chainId={chainId}
                    isConnected={isConnected}
                    open={open}
                    status={status}
                    provider={provider}
                />
                <div style={{ width: '100%', position: "fixed", bottom: 0 }}>
                    <Footer
                        isConnected={isConnected}
                        open={open}
                        address={address}
                    />
                </div>
            </div>
            <WebVersion />
        </div>
    );
}

export default App;